<template>
  <v-expand-transition>
    <div v-show="obj.isOpen">
      <v-form v-model="isValid" ref="form">
        <v-card-text class="py-0 pr-0">
          <!-- 平場・擦り付け -->
          <v-card-actions class="pr-0">
            {{ $t("FLATGROUND_PARAMETERS") }}
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(0)">
              <v-icon>
                {{ isPanelActive(0) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(0)">
              <v-table density="compact">
                <tbody>
                  <!-- 法面あり -->
                  <tr>
                    <td>
                      {{ $t("With_Slope") }}
                    </td>
                    <td>
                      <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                        @update:model-value="updateSettings"
                        v-model="obj.settings.commonSettings.withSlope"></v-checkbox>
                    </td>
                  </tr>
                  <!-- 法面ピッチ -->
                  <tr>
                    <td>
                      {{ $t("FLATGROUND_INTERVAL") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.1"
                        suffix="m" :disabled="!obj.edit || !withSlope" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.interpolationPitch"
                        :rules="!withSlope ? [] : [rules.fgValidInterpolation]" />
                    </td>
                  </tr>
                  <!-- 摺り付け点群 -->
                  <tr v-if="pointClouds.length > 0">
                    <td>{{ $t("ASSOCIATED_POINT_CLOUD") }}</td>
                    <td>
                      <v-select variant="plain" v-model="json.associatePointCloudId" :items="pointClouds"
                        :item-title="(item) => item.getName()" :item-value="(item) => item.getAssetIdNum()"
                        density="compact" hide-details :disabled="!obj.edit" class="ma-0"
                        @update:model-value="updateAssociatedPointCloud()">
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("FLATGROUND_HEIGHT") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" step="0.001"
                        suffix="m" :disabled="!obj.edit" @blur="setFlatGroundPointsUniformHeight()"
                        v-model.number="obj.info.maxHeight" :rules="[rules.decimalLength(3), rules.numberFormat]" />
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <!-- 切土設定 -->
          <v-card-actions class="pr-0">
            {{ $t("Cutsetting") }}
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(1)">
              <v-icon>
                {{ isPanelActive(1) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(1)">
              <v-table density="compact">
                <tbody>
                  <!-- 法面高さ -->
                  <tr>
                    <td>
                      {{ $t("Slopeheight") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m" :rules="!obj.edit || (!obj.settings.cutSettings.generateShelf && !withSlope)
                          ? []
                          : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]
                          " :disabled="!obj.edit || (!obj.settings.cutSettings.generateShelf && !withSlope)"
                        @blur="onBlur" v-model.number="obj.settings.cutSettings.height" />
                    </td>
                  </tr>
                  <!-- 法面勾配 -->
                  <tr>
                    <td>
                      {{ $t("Slopegradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                        :is-editable="obj.edit && withSlope" :slope-value="obj.settings.cutSettings.moundSlope"
                        :step="0.01" @blur="onBlur" v-model.number="obj.settings.cutSettings.moundSlope"
                        :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <!-- 小段あり -->
                  <tr>
                    <td>
                      {{ $t("Thereisberm") }}
                    </td>
                    <td>
                      <v-checkbox v-model="obj.settings.cutSettings.generateShelf" hide-details density="compact"
                        class="ma-0" :disabled="!obj.edit || !withSlope"
                        @update:model-value="updateSettings()"></v-checkbox>
                    </td>
                  </tr>
                  <!-- 小段幅 -->
                  <tr>
                    <td>
                      {{ $t("Bermwidth") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m"
                        :rules="!obj.edit || (!obj.settings.cutSettings.generateShelf && !withSlope) ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                        :disabled="!obj.edit || !obj.settings.cutSettings.generateShelf || !withSlope" @blur="onBlur"
                        v-model.number="obj.settings.cutSettings.shelfWidth" />
                    </td>
                  </tr>
                  <!-- 小段勾配 -->
                  <tr>
                    <td>
                      {{ $t("Bermgradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                        :is-editable="obj.edit && obj.settings.cutSettings.generateShelf && withSlope"
                        :slope-value="obj.settings.cutSettings.shelfSlope" :step="0.01" @blur="onBlur"
                        v-model.number="obj.settings.cutSettings.shelfSlope"
                        :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                        :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("Flatgroundcolor") }}
                    </td>
                    <td>
                      <v-menu :disabled="!obj.edit || !withSlope">
                        <template v-slot:activator="{ props }">
                          <v-avatar :style="horizontalTerrainColorStyle" :color="obj.settings.cutSettings.color"
                            size="20px" v-bind="props"> </v-avatar>
                        </template>
                        <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                          v-model="obj.settings.cutSettings.color"></v-color-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <!-- 盛土設定 -->
          <v-card-actions class="pr-0">
            {{ $t("Fillsetting") }}
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(2)">
              <v-icon>
                {{ isPanelActive(2) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(2)">
              <v-table density="compact">
                <tbody>
                  <!-- 法面高さ -->
                  <tr>
                    <td>
                      {{ $t("Slopeheight") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m" :rules="!obj.edit || (!obj.settings.embankmentSettings.generateShelf && !withSlope)
                          ? []
                          : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]
                          " :disabled="!obj.edit || (!obj.settings.embankmentSettings.generateShelf && !withSlope)"
                        @blur="onBlur" v-model.number="obj.settings.embankmentSettings.height" />
                    </td>
                  </tr>
                  <!-- 法面勾配 -->
                  <tr>
                    <td>
                      {{ $t("Slopegradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                        :is-editable="obj.edit && withSlope" :slope-value="obj.settings.embankmentSettings.moundSlope"
                        :step="0.01" @blur="onBlur" v-model.number="obj.settings.embankmentSettings.moundSlope"
                        :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <!-- 小段あり -->
                  <tr>
                    <td>
                      {{ $t("Thereisberm") }}
                    </td>
                    <td>
                      <v-checkbox v-model="obj.settings.embankmentSettings.generateShelf" hide-details density="compact"
                        class="ma-0" :disabled="!obj.edit || !withSlope"
                        @update:model-value="updateSettings()"></v-checkbox>
                    </td>
                  </tr>
                  <!-- 小段幅 -->
                  <tr>
                    <td>
                      {{ $t("Bermwidth") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m"
                        :rules="!obj.edit || (!obj.settings.cutSettings.generateShelf && !withSlope) ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                        :disabled="!(obj.edit && obj.settings.embankmentSettings.generateShelf && withSlope)"
                        @blur="onBlur" v-model.number="obj.settings.embankmentSettings.shelfWidth" />
                    </td>
                  </tr>
                  <!-- 小段勾配 -->
                  <tr>
                    <td>
                      {{ $t("Bermgradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                        :is-editable="obj.edit && obj.settings.embankmentSettings.generateShelf && withSlope"
                        :slope-value="obj.settings.embankmentSettings.shelfSlope" :step="0.01" @blur="onBlur"
                        v-model.number="obj.settings.embankmentSettings.shelfSlope"
                        :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                        :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("Flatgroundcolor") }}
                    </td>
                    <td>
                      <v-menu :disabled="!obj.edit || !withSlope">
                        <template v-slot:activator="{ props }">
                          <v-avatar :style="horizontalTerrainColorStyle" :color="obj.settings.embankmentSettings.color"
                            size="20px" v-bind="props"> </v-avatar>
                        </template>
                        <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                          v-model="obj.settings.embankmentSettings.color"></v-color-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <!-- 土量 -->
          <v-card-actions class="pr-0" v-show="config.useSoilVolumeCalculation">
            {{ $t("Soilvolume") }}
            <v-btn height="20px" icon :disabled="!obj.edit" @click="calculateFlatGroundVolume(obj)" class="ml-2"
              title="ボリュームを計算する" :loading="obj.loadingVolume">
              <v-icon> mdi-play-circle-outline </v-icon>
            </v-btn>
            <v-btn height="20px" class="ml-auto" icon @click="togglePanel(3)">
              <v-icon>
                {{ isPanelActive(3) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(3)">
              <v-table density="compact">
                <tbody>
                  <tr>
                    <td>
                      {{ $t("Cut") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.1"
                        :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.cutVolume" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("FILL") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.1"
                        :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.fillVolume" />
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <v-card-actions class="pr-0" v-show="config.useSurfaceAreaCalculation">
            {{ $t("SurfaceArea") }}
            <v-btn height="20px" icon :disabled="!obj.edit" @click="calculateSurfaceArea(obj)" class="ml-3"
              title="ボリュームを計算する">
              <v-icon> mdi-play-circle-outline </v-icon>
            </v-btn>
            <v-btn height="20px" class="ml-auto" icon @click="togglePanel(4)">
              <v-icon>
                {{ isPanelActive(4) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(4)">
              <v-table density="compact">
                <tbody>
                  <tr>
                    <td>
                      {{ $t("AREA") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.1"
                        :suffix="$t('METERS_SQUARED_UNIT')" readonly v-model="obj.surfaceArea" />
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <v-card-actions class="py-0 mr-3">
            {{ $t("OPACITY") }}
            <v-slider @end="saveChanges()" v-model="obj.transparency" :disabled="!obj.edit" hide-details max="1"
              step="0.01" class="ml-1" color="primary" track-size="0.5" thumb-size="14"></v-slider>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </div>
  </v-expand-transition>
</template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import SlopeSettingInput from "../SlopeSettingInput.vue";
import { UnitTypes } from "../../../constant";
import {
	uploadObjAndNotify,
	updateFlatGroundSettingsInViewer,
} from "../../../utils/cesium-common";
import * as constants from "@/constant.js";
import * as cesiumCommon from "@/utils/cesium-common";
import { useObjectPanel } from "./composables/useObjectPanel";

export default {
	setup(props) {
		console.log(props.objId);
		const { obj, isValid, form, saveChanges } = useObjectPanel(props.objId);
		console.log(obj);
		console.log("=------------------=");
		console.log(obj.edit);
		console.log("=------------------=");
		return {
			obj,
			isValid,
			form,
			saveChanges,
		};
	},
	name: "FlatGroundPanel",
	components: {
		SlopeSettingInput,
	},
	props: {
		objId: String,
		rules: Object,
	},
	computed: {
		pointClouds() {
			return this.$store.state.point_cloud_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		/**
		 * 水平地形色のスタイル
		 */
		horizontalTerrainColorStyle() {
			// 編集不可のときに適用するCSS
			if (!this.obj.edit || !this.withSlope) {
				return {
					opacity: 0.5,
					"pointer-events": "none",
				};
			} else {
				// 編集可のときに適用するCSS
				return {
					opacity: 1.0,
				};
			}
		},
		withSlope: {
			get() {
				return this.obj.settings.commonSettings.withSlope;
			},
			set(value) {
				this.obj.settings.commonSettings.withSlope = value;
			},
		},
		cutSettingSlopeUnit() {
			return this.$store.state.cutSettingSlopeUnit;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
	},
	data() {
		return {
			config,
			activePanel: null,
			json: {},
		};
	},
	methods: {
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		async onBlur() {
			this.$store.dispatch("startObjectUpdating");
			await this.updateSettings();
		},
		updateSettings() {
			return this.$nextTick(async () => {
				this.$refs.form.validate();
				if (!this.isValid) return;
				await updateFlatGroundSettingsInViewer(this.obj.id, this.obj);
				await uploadObjAndNotify(this.obj, constants.objectType.FLAT);
			});
		},
		initializeJson() {
			this.json = JSON.parse(this.obj.json);
		},
		updateJsonSettings() {
			this.obj.json = JSON.stringify(this.json);
		},
		async updateAssociatedPointCloud() {
			let hasUpdateError = false;
			try {
				// viewerの摺り付け点群を更新
				await cesiumCommon.updatePointCloudAssociatedWithObj(
					this.obj.id,
					this.json.associatePointCloudId,
					constants.objectType.FLAT,
				);
				// objオブジェクトのjsonを更新
				this.updateJsonSettings();
				// データ削除区分
				this.$emit("road-panel-data", true);
			} catch {
				hasUpdateError = true;
				// エラーが発生したらJSONを元に戻す
				this.initializeJson();
			}
			// 変更内容を保存
			if (!hasUpdateError)
				uploadObjAndNotify(this.obj, constants.objectType.FLAT);
		},
		isPanelActive(id) {
			return this.activePanel === id;
		},
		togglePanel(id) {
			if (this.activePanel === id) {
				this.activePanel = null; // アクティブなアコーディオンを閉じる
			} else {
				this.activePanel = id; // 新しいアコーディオンをアクティブにする
			}
		},
		async calculateFlatGroundVolume(obj) {
			obj.loadingVolume = true;
			try {
				const res = window["viewer"].volumeCalculationForFlatGround(obj.id);
				obj.cutVolume = res.cutVolume.toFixed(3);
				obj.fillVolume = res.fillVolume.toFixed(3);
			} catch (error) {
				console.error(error.message);
			} finally {
				obj.loadingVolume = false;
			}
			this.$forceUpdate();
		},
		calculateSurfaceArea(obj) {
			const res = window["viewer"].calculateSurfaceAreaOfFlatGround(obj.id);
			obj.surfaceArea = res.toFixed(3);
			console.log(obj.surfaceArea);
		},
		async setFlatGroundPointsUniformHeight() {
			if (this.obj.id === null || !this.isValid) return;
			await window["viewer"].setFlatGroundPointsUniformHeight(
				this.obj.id,
				Number.parseFloat(this.obj.info.maxHeight),
			);
			uploadObjAndNotify(this.obj, constants.objectType.FLAT);
		},
	},
	mounted() {
		this.initializeJson();
	},
};
</script>

<style scoped>
tr td {
  padding: 0 8px !important;
}

tr td:first-child {
  min-width: 84px;
}
</style>
