import store from "@/store/index";
// WebSocket endpoint
let isTransformerActive = false;
let isloopRunning = false;
const requestDataQueueFIFO = []; //{ points:points[], replacable: boolean}
let appLaumchPromiseResolve;
let singlePromiseResolve;
let singlePromiseReject;
let singlePromiseData;
let retryCount = 0;
let wsServerUrl = "";
const MAX_RETRY_COUNT = 3;

function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
}

let wsConvertToPointCloud;

export const initWebSocket = (serverUrl) => {
	wsServerUrl = serverUrl;
	wsConvertToPointCloud = new WebSocket(wsServerUrl);
	let resolve;

	wsConvertToPointCloud.addEventListener("open", (event) => {
		resolve && resolve();
	});

	// WebSocket error event.
	wsConvertToPointCloud.addEventListener("error", (error) => {
		isTransformerActive = false;
		isloopRunning = false;
		console.error("WebSocket error:", error);
	});

	// WebSocket close event.
	wsConvertToPointCloud.addEventListener("close", (code, reason) => {
		isTransformerActive = false;
		isloopRunning = false;
		if (singlePromiseData && singlePromiseResolve && singlePromiseReject) {
			if (retryCount < MAX_RETRY_COUNT) {
				retryCount++;
				requestDataQueueFIFO.unshift({
					points: singlePromiseData,
					resolve: singlePromiseResolve,
					reject: singlePromiseReject,
				});
				runloop();
			} else {
				singlePromiseReject();
				singlePromiseResolve = undefined;
				singlePromiseReject = undefined;
				singlePromiseData = undefined;
				retryCount = 0;
			}
		}
	});

	wsConvertToPointCloud.addEventListener("message", (event) => {
		const messageObj = JSON.parse(event.data);
		if (messageObj.eventName == "LAUNCHING_APP_FINISH") {
			isTransformerActive = true;
			appLaumchPromiseResolve && appLaumchPromiseResolve();
		}

		if (messageObj.eventName == "PROCESS_FINISH") {
			singlePromiseResolve && singlePromiseResolve(messageObj.data);
			singlePromiseResolve = undefined;
			singlePromiseReject = undefined;
			singlePromiseData = undefined;
			retryCount = 0;
		}

		if (messageObj.eventName == "CLOSING_APP_FINISH") {
			isTransformerActive = false;
			isloopRunning = false;
			singlePromiseResolve = undefined;
			singlePromiseReject = undefined;
			singlePromiseData = undefined;
			retryCount = 0;
		}
		if (messageObj.eventName == "FAIL") {
			isTransformerActive = false;
			isloopRunning = false;
			singlePromiseReject && singlePromiseReject();
			singlePromiseResolve = undefined;
			singlePromiseReject = undefined;
			singlePromiseData = undefined;
			retryCount = 0;
		}
	});
	return new Promise((_resolve) => {
		resolve = _resolve;
	});
};

// Connection opened

const transformPoints = (points) => {
	return new Promise((resolve, reject) => {
		if (isTransformerActive) {
			singlePromiseResolve = resolve;
			singlePromiseReject = reject;
			singlePromiseData = points;
			wsConvertToPointCloud.send(
				JSON.stringify({
					eventName: "PROCESS",
					data: {
						points: points,
					},
				}),
			);
		} else {
			reject();
			singlePromiseReject = undefined;
			singlePromiseResolve = undefined;
			singlePromiseData = undefined;
			retryCount = 0;
		}
	});
};

const recursive = (data) => {
	transformPoints(data.points)
		.then((transformedPoints) => {
			data.resolve(transformedPoints);
		})
		.catch((e) => {
			data.reject(e);
		})
		.finally(() => {
			const nextData = requestDataQueueFIFO.shift();
			if (nextData) {
				recursive(nextData);
			} else {
				isloopRunning = false;
				endinitCRSConversionToPointCloudCRS();
			}
		});
};

const runloop = async () => {
	if (!isloopRunning) {
		if (requestDataQueueFIFO.length > 0) {
			isloopRunning = true;
			if (!isTransformerActive) {
				openWebSocketIfClosed().then(() => {
					initCRSConversionToPointCloudCRS().finally(() => {
						const data = requestDataQueueFIFO.shift();
						recursive(data);
					});
				});
			} else {
				const data = requestDataQueueFIFO.shift();
				recursive(data);
			}
		}
	}
};

const getCoordinatesToPointCloudCrsConversion = () => {
	//{x,y,z}[]
	//epsg
	const epsg = store.state.site.epsg;
	const epsg_v = store.state.site.epsg_v;

	//proj string
	const rotation = store.state.site.rotation;
	const origin_easting = store.state.site.origin_easting;
	const origin_northing = store.state.site.origin_northing;
	const origin_latitude = store.state.site.origin_latitude;
	const origin_longitude = store.state.site.origin_longitude;
	const scale_factor = store.state.site.scale_factor;
	const vertical_offset = store.state.site.vertical_offset;
	const incline_x = store.state.site.incline_x;
	const incline_y = store.state.site.incline_y;

	let data;

	if (!(epsg === null || epsg === undefined || epsg === "")) {
		data = {
			from: "EPSG:4978",
			to: `${epsg}` + (epsg_v ? `+${epsg_v}` : ""),
		};
	}

	if (!data) {
		if (!(rotation === null || rotation === undefined || rotation === "")) {
			data = {
				stereoParameters: [
					`rotation=${rotation}`,
					`origin_easting=${origin_easting}`,
					`origin_northing=${origin_northing}`,
					`origin_latitude=${origin_latitude}`,
					`origin_longitude=${origin_longitude}`,
					`scale_factor=${scale_factor}`,
					`vertical_offset=${vertical_offset}`,
					`incline_x=${incline_x}`,
					`incline_y=${incline_y}`,
				],
				stereoParametersKeyword: "--wgs84-to-local",
				from: "EPSG:4978",
				to: "EPSG:4326+ESRI:115700",
			};
		}
	}
	return data;
};

export const initCRSConversionToPointCloudCRS = () => {
	return new Promise((resolve, reject) => {
		if (!wsConvertToPointCloud) {
			reject("websocket is not active");
		}
		const data = getCoordinatesToPointCloudCrsConversion();
		if (data) {
			wsConvertToPointCloud.send(
				JSON.stringify({
					eventName: "LAUNCHING_APP",
					data: data,
				}),
			);
		} else {
			reject();
		}
		appLaumchPromiseResolve = resolve;
	});
};

const timeout = 200 * 10 * 60; //2 seconds
const endinitCRSConversionToPointCloudCRS = debounce(() => {
	if (!isloopRunning) {
		if (wsConvertToPointCloud) {
			wsConvertToPointCloud.send(
				JSON.stringify({
					eventName: "CLOSING_APP",
				}),
			);
		} else {
			console.warn("Websocket is not available");
		}
	}
}, timeout);

export const convertPointsToPointCloudCRS = async (points, replaceable) => {
	for (let i = 0; i < requestDataQueueFIFO.length - 1; ++i) {
		if (
			requestDataQueueFIFO[i].replaceable &&
			!requestDataQueueFIFO[i].replaceable.isOnProcessing
		) {
			requestDataQueueFIFO[i].replaceable.reject();
			requestDataQueueFIFO.splice(i, 1);
			--i;
		}
	}
	const reqPromise = new Promise((resolve, reject) => {
		requestDataQueueFIFO.push({
			points,
			replaceable,
			resolve,
			reject,
			isOnProcessing: false,
		});
	});
	runloop();
	return reqPromise;
};

const openWebSocketIfClosed = async () => {
	if (!wsConvertToPointCloud) {
		console.warn("wsConvertToPointCloud is not initialized");
		return;
	}
	if (
		wsConvertToPointCloud.readyState === 2 ||
		wsConvertToPointCloud.readyState === 3
	) {
		//closing or closed
		await initWebSocket(wsServerUrl);
	}
};
