<template>
	<div>
		<v-dialog :scrim="false" v-model="showAddDataDialog" width="40%">
			<select-type @close="closeDialog()" @open-terrain-data="openTerrainData" />
		</v-dialog>
		<v-dialog v-model="showTerrainDataDialog" width="600px" persistent>
			<add-terrain-data ref="add_terrain_data" @upload="uploadTerrainData" @close="closeTerrainData"
				:allowedFileTypes="allowedFileTypes" :axis_show="axisShow" :is_show="showTerrainDataDialog"
				:is_drawing_2d_image="isDrawing2dImage" />
		</v-dialog>
		<v-dialog v-model="showDrawing2DImageConstructionDialog" width="600px" persistent>
			<save-reference-topology-dialog ref="add_terrain_data" @save="saveReferenceTopology"
				@close="closeReferenceTopology" />
		</v-dialog>
		<add-data-from-groupware-sequence v-if="showGetFromGroupwareDialog" @close="closeGetFromGroupwareDialog" />
	</div>
</template>

<script>
import axios from "axios";
import { mapActions, mapMutations } from "vuex";
import {
	getCornerPositions,
	addDrawing2dImage,
	toggleDrawing2dImageVisibility,
} from "../../utils/cesium-common";
import SelectType from "../AddData/SelectType.vue";
import addTerrainData from "../AddData/addTerrainData.vue";
import saveReferenceTopologyDialog from "../AddData/saveReferenceTopologyDialog.vue";
import addDataFromGroupwareSequence from "../AddData/addDataFromGroupwareSequence.vue";
import { pickPositions } from "../../utils/cesium-common";
import { Math as CesiumMath, Cartographic } from "cesium";
import { Drawing2dImage } from "../../models/Drawing2DImage";

const DEFAULT_IMAGE_WIDTH = 100;

export default {
	components: {
		SelectType,
		addTerrainData,
		saveReferenceTopologyDialog,
		addDataFromGroupwareSequence,
	},
	props: {
		modelValue: Boolean,
	},
	data() {
		return {
			type: "",
			showTerrainDataDialog: false,
			showDrawing2DImageConstructionDialog: false,
			showGetFromGroupwareDialog: false,
			axisShow: false,
			allowedFileTypes: "",
			isDrawing2dImage: true,
			origin_latitude: 0,
			origin_longitude: 0,
			drawing2DImageConstructionData: undefined,
		};
	},
	mounted() {
		window.addEventListener("createDrawing2dImage", this.createDrawing2dImage);
	},
	computed: {
		showAddDataDialog: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit("update:modelValue", value);
			},
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions(["get_point_cloud_list", "get_design_list"]),
		...mapActions("drawing2dImage", [
			"getDrawing2dImageList",
			"addDrawing2dImage",
		]),
		closeDialog() {
			this.$emit("close");
		},
		createDrawing2dImage(event) {
			console.log("createDrawing2dImage", event.detail);
			this.type = "drawing2dImage";
			this.allowedFileTypes = "";
			this.axisShow = false;
			this.isDrawing2dImage = true;
			this.showDrawing2DImageConstructionDialog = true;
			this.drawing2DImageConstructionData = event.detail;
		},
		async openTerrainData(
			type,
			allowedFileTypes,
			axisShow,
			isDrawing2dImage,
			originCartographic,
		) {
			// 選択ダイアログを閉じる
			this.showAddDataDialog = false;

			if (type === "groupware") {
				this.showGetFromGroupwareDialog = true;
				return;
			}

			if (isDrawing2dImage) {
				const { origin_latitude, origin_longitude } = this.$store.getters.site;
				if (!origin_latitude || !origin_longitude) {
					this.set_snackbar({
						text: this.$t("CLICK_ON_THE_MAP_TO_SET_IMAGE"),
						color: "rgba(255, 215, 0, 0.72)",
					});

					const positionCartesianList = await pickPositions(1);
					const positionCartographic = Cartographic.fromCartesian(
						positionCartesianList[0],
					);

					this.origin_latitude = CesiumMath.toDegrees(
						Number(positionCartographic.latitude),
					);
					this.origin_longitude = CesiumMath.toDegrees(
						Number(positionCartographic.longitude),
					);
				} else {
					this.origin_latitude = origin_latitude;
					this.origin_longitude = origin_longitude;
				}
			}

			// アップロードダイアログを開く
			this.type = type;
			this.allowedFileTypes = allowedFileTypes;
			this.axisShow = axisShow;
			this.isDrawing2dImage = isDrawing2dImage;
			this.showTerrainDataDialog = true;
		},
		closeGetFromGroupwareDialog() {
			this.showGetFromGroupwareDialog = false;
		},
		closeTerrainData() {
			this.type = "";
			this.allowedFileTypes = "";
			this.axisShow = false;
			this.showTerrainDataDialog = false;
			this.showDrawing2DImageConstructionDialog = false;
			this.isDrawing2dImage = false;
			this.imageWidthAndHeight = { width: 50, height: 50 };
		},
		async uploadTerrainData(dialogData) {
			if (dialogData) {
				this.$store.dispatch("updateIsLoading", true);
				const fd = new FormData();
				fd.append("file", dialogData[0]);
				fd.append("name", dialogData[1]);
				fd.append("date", dialogData[2]);
				fd.append("time", dialogData[3] + ":00");
				if (this.axisShow) {
					fd.append("axis", dialogData[4]);
				}

				if (this.type === "drawing2dImage") {
					fd.append("isDraped", dialogData[4]);
					fd.append("centerLatitude", this.origin_latitude);
					fd.append("centerLongitude", this.origin_longitude);

					function getImageDimensions(file) {
						return new Promise((resolve, reject) => {
							const img = new Image();

							img.src = URL.createObjectURL(file);

							img.onload = () => {
								const dimensions = {
									width: img.width,
									height: img.height,
								};

								// Prevent memory leaks
								URL.revokeObjectURL(img.src);

								resolve(dimensions);
							};

							img.onerror = (error) => {
								reject(error);
							};
						});
					}

					const dimensions = await getImageDimensions(dialogData[0]);

					this.imageWidthAndHeight = {
						width: DEFAULT_IMAGE_WIDTH,
						height: Math.round(
							(DEFAULT_IMAGE_WIDTH / dimensions.width) * dimensions.height,
						),
					};

					fd.append("imageWidth", this.imageWidthAndHeight.width);
					fd.append("imageHeight", this.imageWidthAndHeight.height);
				}

				const extension = dialogData[0].name.substring(
					dialogData[0].name.lastIndexOf(".") + 1,
				);
				let apiKey = this.type;
				if (extension.toLowerCase() === "sim") {
					apiKey = "linearfile";
				}

				try {
					const res = await axios.post(
						`${import.meta.env.VITE_API_BASE}/${apiKey}/${this.$route.query.siteId}`,
						fd,
						{
							auth: this.$store.state.authObject,
						},
					);
					switch (this.type) {
						case "pointcloud": {
							await this.get_point_cloud_list(this.$route.query.siteId);
							break;
						}
						case "design": {
							await this.get_design_list(this.$route.query.siteId);
							break;
						}
						case "lineworks": {
							if (extension.toLowerCase() === "dxf") {
								const line = {
									data_type: "lineworks",
									line_type: "dxf",
									lineworks_id: res.data.lineworks_id,
									create_at: res.data.create_at,
									lineworks_name: res.data.lineworks_name,
									lineworks_path: res.data.lineworks_path,
									processing_status: res.data.processing_status,
									site_id: res.data.site_id,
								};
								this.$store.commit("add_design", line);
							} else {
								const line = res.data;
								line.data_type = "lineworks";
								this.$store.commit("add_design", line);
							}
							break;
						}
						case "drawing2dImage": {
							await this.getDrawing2dImageList(this.$route.query.siteId);
							break;
						}
					}
				} catch (e) {
					if (typeof e.toJSON === "function" && e.toJSON().status == 504) {
						this.set_snackbar({
							text: `${dialogData[1]}\n${this.$t("entity_upload_wait_msg")}`,
							color: "rgba(255, 215, 0, 0.72)",
						});
					} else {
						this.set_snackbar({
							text: `${dialogData[1]} ${this.$t("UPLOAD")} ${this.$t("failed")}.`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				} finally {
					this.$store.dispatch("updateIsLoading", false);
					this.closeTerrainData();
				}

				// アップロードリクエストから1秒毎にデータリストをリフレッシュ
				// let count = 0;
				// const timeId = setInterval(async () => {
				//   count++;
				//   if (count === 10) {
				//     clearInterval(timeId);
				//   }
				//   try {
				//     if (entity == "pointcloud") {
				//       await this.get_point_cloud_list(this.$route.query.siteId)
				//     } else {
				//       await this.get_design_list(this.$route.query.siteId)
				//     }
				//   } catch (error) {
				//     console.error(error.message);
				//   }
				// },1000);
			} else {
				this.closeTerrainData();
			}
		},
		async saveReferenceTopology(dialogData) {
			if (dialogData) {
				this.$store.dispatch("updateIsLoading", true);
				try {
					const res = await axios.post(
						`${import.meta.env.VITE_API_BASE}/drawing2dImage/noImage/${this.$route.query.siteId}`,
						{
							name: dialogData[0],
							date: dialogData[1],
							time: dialogData[2] + ":00",
							isDraped: dialogData[3],
							centerLatitude: this.origin_latitude,
							centerLongitude: this.origin_longitude,
							json: {
								version: 1,
								centerLatitude: this.origin_latitude,
								centerLongitude: this.origin_longitude,
								cornerPositions:
									this.drawing2DImageConstructionData.cornerPositions,
								imageWidth: 0,
								imageHeight: 0,
								isDraped: dialogData[3],
								transparency: 0.5,
								centerHeight: this.drawing2DImageConstructionData.elevation,
								modelMatrix: [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
							},
						},
						{
							auth: this.$store.state.authObject,
						},
					);
					// Todo: APIが実装されたら、以下のダミーデータを削除する below
					const { site_id } = this.$store.getters.site;
					const captureAt = new Date(`${dialogData[1]} ${dialogData[2]}:00`);
					const drawing2dImage = new Drawing2dImage({
						file: null,
						name: dialogData[0],
						fileName: "",
						assetId: res.data.id,
						isAssetUploaded: true,
						transparency: 0.5,
						isVisible: false,
						selectedDate: captureAt.toLocaleDateString(),
						selectedTime: captureAt.toLocaleTimeString(),
					});
					await toggleDrawing2dImageVisibility(drawing2dImage);
					this.addDrawing2dImage({
						siteId: site_id,
						drawing2dImage,
					});
				} catch (e) {
					if (typeof e.toJSON === "function" && e.toJSON().status == 504) {
						this.set_snackbar({
							text: `${dialogData[1]}\n${this.$t("entity_upload_wait_msg")}`,
							color: "rgba(255, 215, 0, 0.72)",
						});
					} else {
						this.set_snackbar({
							text: `${dialogData[1]} ${this.$t("UPLOAD")} ${this.$t("failed")}.`,
							color: "rgba(153, 0, 0, 0.72)",
						});
					}
				} finally {
					this.$store.dispatch("updateIsLoading", false);
					this.closeTerrainData();
				}
			} else {
				this.closeTerrainData();
			}
		},
	},
};
</script>
