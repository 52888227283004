<template>
	<v-navigation-drawer permanent :rail="this.mini" rail-width="48" width="380" mobile-breakpoint="0" color="panel_sub"
		theme="dark">
		<v-list-item :class="this.mini ? 'px-0' : ''">
			<template v-slot:prepend v-if="mini">
				<v-btn icon variant="text" @click.stop="toggleToolPanelMini()">
					<v-icon> icon:Chevron-Right</v-icon>
				</v-btn>
			</template>
			<template v-slot:title>
				<span @click="gotoList()" class="hover-blue">
					{{ $t("PROJECT") }}
				</span>
				<v-icon>icon:Chevron-Right</v-icon>
				{{ this.$store.state.site ? this.$store.state.site.site_name : "" }}
			</template>
			<template v-slot:append>
				<v-btn icon variant="text" @click.stop="toggleToolPanelMini()">
					<v-icon> icon:Chevron-Left </v-icon>
				</v-btn>
			</template>
		</v-list-item>
		<div class="left-area d-flex flex-row">
			<v-tabs v-model="tab" direction="vertical" bg-color="panel_sub" color="primary">
				<v-tab value="tab1" class="px-0 justify-center" @click="homeIconHandler">
					<v-icon> icon:Home </v-icon>
				</v-tab>
				<v-tab value="tab2" class="px-0 justify-center">
					<v-icon> icon:Setting </v-icon>
				</v-tab>
				<v-tab value="tab3" class="px-0 justify-center">
					<v-icon> mdi-rotate-45 mdi-ruler </v-icon>
				</v-tab>
				<div class="outer-link">
					<v-menu transition="slide-y-transition" location="end">
						<template v-slot:activator="{ props }">
							<info-tooltip placement="right" :triggers="['hover']">
								<v-tab :value="tab" v-bind="props" class="px-0 justify-center">
									<i class="fa-solid fa-up-right-from-square"></i>
								</v-tab>
								<template #popper>
									<span class="text-black">
										{{ $t("TOOLTIP_LABEL_OPEN_THE_EXTERNAL_PAGE") }}
									</span>
								</template>
							</info-tooltip>
						</template>
						<v-list density="compact">
							<v-list-item @click="openDashboardSite">
								<v-icon>icon:Dashboard</v-icon>
								{{ $t("LABEL_DASHBOARD") }}
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</v-tabs>
			<v-window v-model="tab" direction="vertical" class="panel-main">
				<!-- ホームタブ -->
				<v-window-item value="tab1" class="tab">
					<v-card class="panel-main" color="panel_main">
						<v-card-title class="py-2 d-flex align-center justify-space-between">
							{{ $t("DATA_LAYERS") }}
							<v-btn :disabled="$store.getters.isLoading" class="pa-0" height="22px" width="22px" min-width="0"
								@click="fetchDataList" icon>
								<v-icon color="white">mdi-sync</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-actions class="panel-main-item">
							<v-btn class="add-btn" variant="flat" color="primary" rounded @click="openAddDataDialog()">
								<template v-slot:prepend>
									<v-icon size="24"> icon:Add</v-icon>
								</template>
								{{ $t("ADD_DATA_LAYER") }}
							</v-btn>
							<add-data-dialog v-model="showAddDataDialog" @close="closeAddDataDialog()" />
						</v-card-actions>
						<!-- オブジェクト選択ダイアログ画面 -->
						<v-card-actions class="panel-main-item">
							<v-btn class="add-btn" variant="flat" color="primary" rounded @click="openObjectDataDialog()"
								:disabled="isCreateBtnDisabled">
								<template v-slot:prepend>
									<v-icon size="24"> icon:Add</v-icon>
								</template>
								{{ $t("BTN_OBJECT") }}
							</v-btn>
							<v-dialog :scrim="false" v-model="showObjectDataDialog" width="40%">
								<object-data-dialog @data-to-parent="receiveData" @close="closeObjectDataDialog()" />
							</v-dialog>
						</v-card-actions>
						<!-- 線形追加ダイアログ -->
						<v-card-actions class="panel-main-item">
							<v-btn class="add-btn" variant="flat" color="primary" rounded @click="openAddLineWorksDialog()">
								<template v-slot:prepend>
									<v-icon size="24"> icon:Add</v-icon>
								</template>
								{{ $t("ADD_LINE_WORKS") }}
							</v-btn>
							<add-line-works-dialog v-model="showAddLineWorksDialog" @data-to-parent="toggleLineWorkCreationMode"
								@close="closeAddLineWorksDialog()" />
						</v-card-actions>
						<v-card-actions>
							<v-dialog :scrim="false" v-model="isExclusion" width="300px">
								<exclusivity-notice-dialog @close="closeExclusivityNoticeDialog()" :exclusive_obj="exclusive_obj" />
							</v-dialog>
						</v-card-actions>
						<!-- drawing 2d image data-->
						<drawing-2d-image-list></drawing-2d-image-list>
						<!-- 基準地形 -->
						<point-cloud-list></point-cloud-list>
						<!-- 設計データ -->
						<design-list></design-list>
						<!-- 線形データ-->
						<line-works-list></line-works-list>
						<!-- グローブ表示切り替え -->
						<v-card-actions class="mx-3 my-3">
							{{ $t("globe") }} {{ $t("show") }} / {{ $t("hide") }}
							<v-switch class="globe-switch" v-model="globeVisibility" inset hide-details color="success"
								@update:model-value="globeVisibilityChanged()"></v-switch>
						</v-card-actions>
						<!-- オブジェクトリスト -->
						<object-list :isRoadAdd="isRoadAdd" :isFlatAdd="isFlatAdd" :isTrenchAdd="isTrenchAdd"
							:globeVisibilityBeforeCreationMode="globeVisibilityBeforeCreationMode"
							:updateObjectListOfCreation="updateObjectListOfCreation" @updateGlobeVisibility="updateGlobeVisibility">
						</object-list>
					</v-card>
				</v-window-item>
				<v-window-item value="tab2" class="tab">
					<v-card class="panel-main" color="panel_main">
						<v-card-title class="py-1">
							{{ $t("PROJECT_SETTINGS") }}
						</v-card-title>
						<v-card-text>
							<v-card-subtitle class="px-0 py-4">
								{{ $t("DISTANCE_UNITS") }}
							</v-card-subtitle>
							<v-table density="compact">
								<tbody>
									<tr>
										<td class="col-header">
											{{ $t("DISTANCE") }}
										</td>
										<td>{{ $t("METERS_WORD") }}</td>
									</tr>
									<tr>
										<td>
											{{ $t("AREA") }}
										</td>
										<td>{{ $t("SQUARE_METERS_WORD") }}</td>
									</tr>
									<tr>
										<td>
											{{ $t("VOLUME") }}
										</td>
										<td>{{ $t("METERS_CUBED_WORD") }}</td>
									</tr>
									<!-- <tr>
										<td>
											{{ $t("SLOPE") }}
										</td>
										<td>
											<v-select v-model="unit" :items="unitTypes" item-text="label" item-value="value" density="compact"
												@change="this.changeSlopeUnit" hide-details class="px-1"></v-select>
										</td>
									</tr> -->
								</tbody>
							</v-table>
							<v-card-subtitle class="px-0 py-4">
								{{ $t("COORDINATE_SYSTEM") }}
							</v-card-subtitle>
							<v-table density="compact">
								<tbody>
									<tr>
										<td>rotation</td>
										<td>
											{{ truncateDecimals(siteInfo?.rotation, 5) }}
										</td>
									</tr>
									<tr>
										<td>origin_easting</td>
										<td>
											{{ truncateDecimals(siteInfo?.origin_easting, 5) }}
										</td>
									</tr>
									<tr>
										<td>origin_northing</td>
										<td>
											{{ truncateDecimals(siteInfo?.origin_northing, 5) }}
										</td>
									</tr>
									<tr>
										<td>origin_latitude</td>
										<td>
											{{ truncateDecimals(siteInfo?.origin_latitude, 5) }}
										</td>
									</tr>
									<tr>
										<td>origin_longitude</td>
										<td>
											{{ truncateDecimals(siteInfo?.origin_longitude, 5) }}
										</td>
									</tr>
									<tr>
										<td>scale_factor</td>
										<td>
											{{ truncateDecimals(siteInfo?.scale_factor, 5) }}
										</td>
									</tr>
									<tr>
										<td>vertical_offset</td>
										<td>
											{{ truncateDecimals(siteInfo?.vertical_offset, 5) }}
										</td>
									</tr>
									<tr>
										<td>incline_x</td>
										<td>
											{{ truncateDecimals(siteInfo?.incline_x, 5) }}
										</td>
									</tr>
									<tr>
										<td>incline_y</td>
										<td>
											{{ truncateDecimals(siteInfo?.incline_y, 5) }}
										</td>
									</tr>
									<tr>
										<td>toMeters</td>
										<td>
											{{ truncateDecimals(siteInfo?.to_meters, 5) }}
										</td>
									</tr>
									<tr>
										<td class="col-header">COORDINATE_SYSTEM</td>
										<td>
											{{ epsgLabel }}
										</td>
									</tr>
									<tr>
										<td>VERTICAL_SHIFT</td>
										<td>
											{{ epsgvLabel }}
										</td>
									</tr>
								</tbody>
							</v-table>
							<v-btn class=".rounded-lg mt-3 mr-5" color="#ff5050" height="25px" variant="flat" @click="showDialog()">
								{{ $t("CHANGE_COORDINATE_SYSTEM") }}
							</v-btn>
						</v-card-text>
					</v-card>
				</v-window-item>
				<v-window-item value="tab3" class="tab">
					<v-card class="panel-main measurement" color="panel_main">
						<v-card-title class="py-1 d-flex align-center justify-space-between">
							{{ $t("MEASUREMENT") }}
							<v-btn :disabled="$store.getters.isLoading" class="pa-0" height="22px" width="22px" min-width="0"
								@click="fetchTwoPointsList" icon>
								<v-icon color="white">mdi-sync</v-icon>
							</v-btn>
						</v-card-title>
						<v-card-actions>
							<v-btn variant="flat" class="py-1 measure-btn" width="180px" color="primary" min-height="31px"
								@click="toggleMeasurementMode">
								{{ $t("CREATE_LINEAR") }}
							</v-btn>
						</v-card-actions>
						<!-- 基準地形 -->
						<measurement-list></measurement-list>
					</v-card>
				</v-window-item>
			</v-window>
		</div>
		<v-dialog v-model="isWarningDialogOpen" width="600px" v-if="isWarningDialogOpen" persistent>
			<change-site-coordinate-system-dialog @close="closeWarningDialog" />
		</v-dialog>
		<v-dialog v-model="isFileDialogOpen" width="600px" v-if="isFileDialogOpen" persistent>
			<local-file-upload @close="closeFileUploadDialog" />
		</v-dialog>
	</v-navigation-drawer>
</template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import * as constants from "@/constant";
import * as lockUtils from "@/utils/lock-utils";
import axios from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { LandXML } from "earthbrain-viewer";
import { mapActions, mapMutations, mapState } from "vuex";
import { /*UnitTypes,*/ objectType } from "../../constant";
import {
	updateRoadSettingsInViewer,
	updateTrenchSettingsInViewer,
	uploadObjAndNotify,
} from "../../utils/cesium-common";
import ChangeSiteCoordinateSystemDialog from "../AddData/ChangeSiteCoordinateSystemDialog.vue";
import LocalFileUpload from "../AddData/LocalFileUpload.vue";
import InfoTooltip from "../common/InfoTooltip.vue";
import AddDataDialog from "./AddDataDialog.vue";
import AddLineWorksDialog from "./AddLineWorksDialog.vue";
import DesignList from "./DesignList";
import Drawing2dImageList from "./Drawing2dImageList.vue";
import ExclusivityNoticeDialog from "./ExclusivityNoticeDialog.vue";
import LineWorksList from "./LineWorks/LineWorksList.vue";
import MeasurementList from "./MeasurementList";
import ObjectDataDialog from "./ObjectDataDialog.vue";
import ObjectList from "./ObjectList.vue";
import PointCloudList from "./PointCloudList";
dayjs.extend(isSameOrAfter);

export default {
	name: "ToolPanel",
	components: {
		AddDataDialog,
		AddLineWorksDialog,
		PointCloudList,
		DesignList,
		ExclusivityNoticeDialog,
		LocalFileUpload,
		ObjectList,
		MeasurementList,
		LineWorksList,
		ObjectDataDialog,
		ChangeSiteCoordinateSystemDialog,
		Drawing2dImageList,
		InfoTooltip,
	},
	mounted() {
		window.addEventListener(
			"deletePointEvent",
			async ({ detail: { id, pointIndex, entityUnderEdit } }) => {
				console.log(id, pointIndex, entityUnderEdit);

				if (entityUnderEdit === objectType.ROAD) {
					const roadSettings = this.$store.state.objects.filter(
						(obj) => obj.cid === id,
					);

					let isDeleteFound = false;
					for (const seg of roadSettings[0].settings.commonSettings
						.segmentSettings) {
						if (isDeleteFound) {
							seg.startIndex--;
							seg.endIndex--;
						} else {
							if (seg.endIndex === pointIndex) {
								seg.endIndex--;
							}
							if (seg.startIndex <= pointIndex && seg.endIndex >= pointIndex) {
								isDeleteFound = true;
								seg.endIndex--;
							}
						}
					}
					roadSettings[0].settings.commonSettings.segmentSettings =
						roadSettings[0].settings.commonSettings.segmentSettings.filter(
							(seg) => seg.startIndex - seg.endIndex !== 0,
						);

					await updateRoadSettingsInViewer(id, roadSettings[0]);
					uploadObjAndNotify(roadSettings[0], constants.objectType.ROAD);
				}

				if (entityUnderEdit === objectType.TRENCH) {
					const trenchSettings = this.$store.state.objects.filter(
						(obj) => obj.cid === id,
					);
					updateTrenchSettingsInViewer(id, trenchSettings[0]);
				}
			},
		);

		window.addEventListener(
			"addPointEvent",
			async ({ detail: { id, pointIndex, entityUnderEdit } }) => {
				console.log(id, pointIndex, entityUnderEdit);

				if (entityUnderEdit === objectType.ROAD) {
					const roadSettings = this.$store.state.objects.filter(
						(obj) => obj.cid === id,
					);
					for (const seg of roadSettings[0].settings.commonSettings
						.segmentSettings) {
						if (seg.startIndex >= pointIndex) {
							seg.startIndex++;
						}
						if (seg.endIndex >= pointIndex) {
							seg.endIndex++;
						}
					}
					await updateRoadSettingsInViewer(id, roadSettings[0]);
					uploadObjAndNotify(roadSettings[0], constants.objectType.ROAD);
				}

				if (entityUnderEdit === objectType.TRENCH) {
					const trenchSettings = this.$store.state.objects.filter(
						(obj) => obj.cid === id,
					);
					for (const seg of trenchSettings[0].settings.commonSettings
						.segmentSettings) {
						if (seg.startIndex >= pointIndex) {
							seg.startIndex++;
						}
						if (seg.endIndex >= pointIndex) {
							seg.endIndex++;
						}
					}
					updateTrenchSettingsInViewer(id, trenchSettings[0]);
				}
			},
		);

		// HACK: Vue2ではthis.$root.$onを使っていたがVue3では動かなかったため、とりあえず動くように修正。できればイベントを使わない形式にしたい。
		window.addEventListener("endCreateMode", this.endCreateMode);

		window.addEventListener("cancelCreateMode", this.cancelCreateMode);

		window.addEventListener("disableAllEdits", this.disableAllEdits);
	},
	data: () => ({
		site: "現場名(モック)",
		config,
		mini: false,
		showAddDataDialog: false,
		showAddLineWorksDialog: false,
		showViewerAgain: false,
		showObjectDataDialog: false,
		showJsonDialog: false,
		showStartEndPointConfirmDialog: false,
		isRoadAdd: false,
		isTrenchAdd: false,
		isAIRoadAdd: false,
		isFlatAdd: false,
		isArbitraryLineAdded: false,
		isStartEndPointConfirm: false,
		globeVisibility: true,
		globeVisibilityBeforeCreationMode: false,
		disableRoadUpdate: false,
		exclusive_obj: null,
		isWarningDialogOpen: false,
		isFileDialogOpen: false,
		updateObjectListOfCreation: false,
		tab: "tab1",
		epsgLabel: "",
		epsgvLabel: "",
	}),
	computed: {
		...mapState(["modes"]),
		siteInfo() {
			return this.$store.getters.site;
		},
		// unitTypes() {
		//   return UnitTypes.map((t) => ({ label: this.$t(t.label), value: t.value }));
		//   // [
		//   //   {
		//   //     label: "Percent",
		//   //     value: "percent",
		//   //     symbol: "%"

		//   //   },
		//   //   {
		//   //     label: this.$t("RATIO_WORD"),
		//   //     value: "ratio",
		//   //     symbol: "1/n"
		//   //   },
		//   //   {
		//   //     label: "Degree",
		//   //     value: "degree",
		//   //     symbol: "°",
		//   //   },
		//   // ];
		// },
		isCreateBtnDisabled() {
			const visibleDrawing2dImageList = this.$store.getters[
				"drawing2dImage/getDrawing2dImageList"
			].filter((drawing2dImage) => drawing2dImage.getIsVisible());
			let enableCreateObject = false;
			//3 cases
			if (
				this.$store.getters.visiblePointClouds.length === 1 &&
				visibleDrawing2dImageList.length === 1
			) {
				enableCreateObject = true;
			} else if (
				this.$store.getters.visiblePointClouds.length === 1 &&
				visibleDrawing2dImageList.length === 0
			) {
				enableCreateObject = true;
			} else if (
				this.$store.getters.visiblePointClouds.length === 0 &&
				visibleDrawing2dImageList.length === 1
			) {
				enableCreateObject = true;
			}
			return !enableCreateObject;
		},
		isExclusion() {
			return this.$store.state.isExclusion;
		},
		cutSettingSlopeUnit() {
			return this.$store.state.cutSettingSlopeUnit;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
		isEdit2dImage() {
			return this.$store.state.modes.inEditDrawing2dImage.isActive;
		},
	},
	watch: {
		isRoadAdd(val) {
			if (val) {
				this.isTrenchAdd = false;
				this.isFlatAdd = false;
				this.isArbitraryLineAdded = false;
			}
		},
		isTrenchAdd(val) {
			if (val) {
				this.isFlatAdd = false;
				this.isRoadAdd = false;
				this.isArbitraryLineAdded = false;
			}
		},
		isFlatAdd(val) {
			if (val) {
				this.isTrenchAdd = false;
				this.isRoadAdd = false;
				this.isArbitraryLineAdded = false;
			}
		},
		isArbitraryLineAdded(val) {
			if (val) {
				this.isFlatAdd = false;
				this.isRoadAdd = false;
				this.isTrenchAdd = false;
			}
		},
		showJsonDialog(val) {
			val && this.disableAllEdits();
			this.isAIRoadAdd = val;
		},
		"siteInfo.epsg": {
			handler() {
				this.setCoordinateSystemLabel();
			},
			immediate: true,
		},
		"siteInfo.epsg_v": {
			handler() {
				this.setVerticalShiftLabel();
			},
			immediate: true,
		},
		isEdit2dImage(val) {
			if (val) {
				this.disableAllEdits();
			}
		},
	},
	methods: {
		...mapMutations([
			"set_snackbar",
			"reset_ai_road_preview_data",
			"set_modes",
			"set_initial_site_data",
		]),
		...mapActions(["get_point_cloud_list", "get_design_list", "get_obj_list"]),
		...mapActions("measurement", ["getMeasurementList"]),
		...mapActions("arbitraryLine", ["getArbitraryLineList"]),
		...mapActions("simaLine", ["getSimaLineList"]),
		...mapActions("drawing2dImage", ["getDrawing2dImageList"]),
		/**
		 * 小数点以下の指定した桁数nを超える部分を切り捨てる
		 * @param {*} num フォーマット対象の数字
		 * @param {*} n 小数点以下の桁数
		 */
		truncateDecimals(num, n) {
			if (typeof num === "number") {
				num = num.toString();
			}

			const regex = /^[+-]?\d+(\.\d+)?$/;
			// フォーマット対象が数値形式かチェック
			if (regex.test(num)) {
				// 小数点以下の桁数がnより多い場合は余分な桁数を切り捨てる
				if (num.indexOf(".") !== -1 && num.split(".")[1].length > n) {
					return Number.parseFloat(num).toFixed(n);
				}
				// 小数点なし or 小数点以下の桁数がn以下の数値は形式だけ変換する
				return Number.parseFloat(num);
			}
			// 数値でない場合はそのまま返す
			return num;
		},
		showWarningDialog() {
			this.isWarningDialogOpen = true;
		},
		closeWarningDialog(isProceed) {
			this.isWarningDialogOpen = false;
			if (isProceed) {
				this.showFileUploadDialog();
			}
		},
		showFileUploadDialog() {
			this.isFileDialogOpen = true;
		},
		closeFileUploadDialog() {
			this.isFileDialogOpen = false;
		},
		showDialog() {
			this.showWarningDialog();
		},
		async fetchDataList() {
			// スピナーを表示する
			this.$store.dispatch("updateIsLoading", true);
			try {
				await Promise.all([
					// 仮設道路
					this.$store.dispatch("get_obj_list", this.$route.query.siteId),
					this.get_point_cloud_list(this.$route.query.siteId),
					this.get_design_list(this.$route.query.siteId),
					this.getDrawing2dImageList(this.$route.query.siteId),
					this.getArbitraryLineList(this.$route.query.siteId),
					this.getSimaLineList(this.$route.query.siteId),
				]);
			} catch (e) {
				console.error(e);
			} finally {
				// スピナーを非表示にする
				this.$store.dispatch("updateIsLoading", false);
			}
		},
		globeVisibilityChanged() {
			window["viewer"].toggleGlobeVisibility(this.globeVisibility);
		},
		addRoadByJson() {
			// AIによる道路追加
			const dummyId = 1;
			const event = new CustomEvent("openPropertyDialog", {
				detail: { id: dummyId, type: 2 },
			});
			window.dispatchEvent(event);
		},
		startEndPointConfirm() {
			this.isStartEndPointConfirm = true;
			this.showStartEndPointConfirmDialog = true;
		},
		closeJsonDialog(dialogData) {
			const points = [];
			this.$store.dispatch("endCreationMode");
			if (dialogData) {
				this.isAIRoadAdd = false;
				this.showJsonDialog = false;
				var file_to_read = dialogData;
				var fileread = new FileReader();
				const epsg = this.$store.state.site.epsg;
				const auth = this.$store.state.authObject;

				fileread.onload = (e) => {
					var content = e.target.result;
					var intern = JSON.parse(content);
					intern.controlPoints.forEach((item) => {
						points.push(item.position);
					});
					axios
						.post(
							`${import.meta.env.VITE_API_BASE}/pointcloud/processroadpoints`,
							{ points, epsg },
							{
								auth,
							},
						)
						.then((res) => {
							console.log(res.data);
							window["viewer"].createRoadFromPoints(
								res.data.points,
								(id) => {
									const event = new CustomEvent("openPropertyDialog", {
										detail: { id: id, type: 0 },
									});
									window.dispatchEvent(event);
								},
								this.$store.getters.activePointCloud?.getAssetIdNum(),
							);
						});
				};
				fileread.readAsText(file_to_read);
			} else {
				this.isAIRoadAdd = false;
				this.showJsonDialog = false;
			}
		},
		closeStartEndPointConfirmDialog(dialogData) {
			const points = [];
			if (dialogData) {
				this.isStartEndPointConfirm = false;
				this.showStartEndPointConfirmDialog = false;
				var file_to_read = dialogData;
				var fileread = new FileReader();
				const epsg = this.$store.state.site.epsg;
				const auth = this.$store.state.authObject;

				fileread.onload = (e) => {
					var content = e.target.result;
					var intern = JSON.parse(content);
					intern.controlPoints.forEach((item) => {
						points.push(item.position);
					});
					axios
						.post(
							`${import.meta.env.VITE_API_BASE}/pointcloud/processroadpoints`,
							{ points, epsg },
							{
								auth,
							},
						)
						.then((res) => {
							console.log(res.data);
							window["viewer"].createRoadFromPoints(
								res.data.points,
								(id) => {
									const event = new CustomEvent("openPropertyDialog", {
										detail: { id: id, type: 0 },
									});
									window.dispatchEvent(event);
								},
								this.$store.getters.activePointCloud?.getAssetIdNum(),
							);
						});
				};
				fileread.readAsText(file_to_read);
			} else {
				this.isStartEndPointConfirm = false;
				this.showStartEndPointConfirmDialog = false;
			}
		},
		toggleToolPanelMini() {
			this.mini = !this.mini;
		},
		updateGlobeVisibility(visibility) {
			this.globeVisibility = visibility;
			window["viewer"].toggleGlobeVisibility(visibility);
		},
		async disableAllEdits() {
			if (this.$store.state.modes.inEditMode.isActive) {
				window["viewer"].clearCommandManager();
				this.$store.dispatch("endEditMode");
				for (const obj of this.$store.state.objects) {
					if (obj.edit) {
						try {
							// ロックを解放
							const lockReleased = await lockUtils.releaseLock(obj.cid);
							if (!lockReleased) continue;

							obj.edit = false;
							if (obj.type === 0) {
								window["viewer"].enableRoadEdit(obj.edit, obj.id, () => {});
							} else if (obj.type === 1) {
								window["viewer"].enableFlatGroundEdit(obj.edit, obj.id);
							} else if (obj.type === 7) {
								window["viewer"].enableTrenchEdit(obj.edit, obj.id);
							}
						} catch (error) {
							console.error(error);
						}
					}
				}
			}
		},
		// 仮設道路追加関数
		roadCreation() {
			this.showViewerAgain = true;
			this.isRoadAdd = !this.isRoadAdd;
			this.disableAllEdits();
			this.updateObjectListOfCreation = !this.updateObjectListOfCreation;
			this.globeVisibilityBeforeCreationMode = this.globeVisibility;
			this.$store.dispatch("startCreationMode", constants.objectType.ROAD);
			const pointCloudId =
				this.$store.getters.activePointCloud?.getAssetIdNum();
			const visibleDrawing2dImageList = this.$store.getters[
				"drawing2dImage/getDrawing2dImageList"
			].filter((drawing2dImage) => drawing2dImage.getIsVisible());
			const drawing2dImageId =
				visibleDrawing2dImageList.length === 1
					? visibleDrawing2dImageList[0].getAssetId()
					: undefined;
			try {
				if (pointCloudId || drawing2dImageId) {
					window["viewer"].enablePathCreation(
						(id) => {
							const event = new CustomEvent("openPropertyDialog", {
								detail: { id: id, type: 0 },
							});
							window.dispatchEvent(event);
						},
						pointCloudId,
						drawing2dImageId,
					);
				} else {
					this.cancelRoadCreation();
					window.dispatchEvent(new CustomEvent("noPointCloudSelected"));
				}
			} catch (e) {
				this.cancelRoadCreation();
				console.error("Error on Road creation");
			}
		},
		// 仮設道路追加キャンセル
		cancelRoadCreation() {
			this.$store.dispatch("endCreationMode");
			this.isRoadAdd = false;
			window["viewer"].cancelRoadCreation();
		},
		// 平場追加
		flatGroundCreation() {
			this.showViewerAgain = true;
			this.isFlatAdd = !this.isFlatAdd;
			this.disableAllEdits();
			this.updateObjectListOfCreation = !this.updateObjectListOfCreation;
			this.globeVisibilityBeforeCreationMode = this.globeVisibility;
			this.$store.dispatch("startCreationMode", constants.objectType.FLAT);
			const pointCloudId =
				this.$store.getters.activePointCloud?.getAssetIdNum();
			const visibleDrawing2dImageList = this.$store.getters[
				"drawing2dImage/getDrawing2dImageList"
			].filter((drawing2dImage) => drawing2dImage.getIsVisible());
			const drawing2dImageId =
				visibleDrawing2dImageList.length === 1
					? visibleDrawing2dImageList[0].getAssetId()
					: undefined;

			try {
				if (pointCloudId || drawing2dImageId) {
					window["viewer"].enablePolygonCreation(
						this.isFlatAdd,
						(id) => {
							const event = new CustomEvent("openPropertyDialog", {
								detail: { id: id, type: 1 },
							});
							window.dispatchEvent(event);
						},
						pointCloudId,
						drawing2dImageId,
					);
				} else {
					this.cancelFlatGroundCreation();
					window.dispatchEvent(new CustomEvent("noPointCloudSelected"));
				}
			} catch {
				this.cancelFlatGroundCreation();
				console.error("Error on Added flat area");
			}
		},
		// 平場追加キャンセル
		cancelFlatGroundCreation() {
			this.$store.dispatch("endCreationMode");
			this.isFlatAdd = false;
			window["viewer"].cancelFlatGroundCreation();
		},
		// 床掘追加
		trenchCreation() {
			this.showViewerAgain = true;
			this.isTrenchAdd = !this.isTrenchAdd;
			this.disableAllEdits();
			this.updateObjectListOfCreation = !this.updateObjectListOfCreation;
			this.globeVisibilityBeforeCreationMode = this.globeVisibility;
			this.$store.dispatch("startCreationMode", constants.objectType.TRENCH);
			const pointCloudId =
				this.$store.getters.activePointCloud?.getAssetIdNum();
			const visibleDrawing2dImageList = this.$store.getters[
				"drawing2dImage/getDrawing2dImageList"
			].filter((drawing2dImage) => drawing2dImage.getIsVisible());
			const drawing2dImageId =
				visibleDrawing2dImageList.length === 1
					? visibleDrawing2dImageList[0].getAssetId()
					: undefined;

			try {
				if (pointCloudId || drawing2dImageId) {
					window["viewer"].enableTrenchCreation(
						(id) => {
							const event = new CustomEvent("openPropertyDialog", {
								detail: { id: id, type: 7 },
							});
							window.dispatchEvent(event);
						},
						pointCloudId,
						drawing2dImageId,
					);
				} else {
					this.cancelTrenchCreation();
					window.dispatchEvent(new CustomEvent("noPointCloudSelected"));
				}
			} catch {
				this.cancelTrenchCreation();
				console.error("Error on Added flat area");
			}
		},
		// 床掘追加キャンセル
		cancelTrenchCreation() {
			this.$store.dispatch("endCreationMode");
			this.isTrenchAdd = false;
			window["viewer"].cancelTrenchCreation();
		},
		isNum(val) {
			return typeof val == "number";
		},
		async exportLandXML(index) {
			const obj = this.$store.state.objects[index];
			const site = this.$store.state.site;
			if (obj.id === null) return;
			let geometry;
			if (obj.type === 0) {
				geometry = window["viewer"].getRoadMeshGeometry(obj.id);
			} else if (obj.type === 1) {
				geometry = window["viewer"].getFlatGroundMeshGeometry(obj.id);
			} else if (obj.type === 7) {
				geometry = window["viewer"].getTrenchMeshGeometry(obj.id);
			}
			if (geometry) {
				try {
					const response = await axios.post(
						`${import.meta.env.VITE_API_BASE}/pointcloud/convertGeometryVertices`,
						{ points: geometry.vertices, epsg: this.$store.state.site.epsg },
						{
							auth: this.$store.state.authObject,
						},
					);
					LandXML.exportMesh(
						response.data.points,
						geometry.indices,
						site.site_name,
						this.createFileName(new Date(), obj.name, site.site_name),
						obj.name,
					);
				} catch (error) {
					console.error(error.message);
				}
			} else {
				console.error("Please check if the entity is created");
			}
		},
		createFileName(date, objName, siteName) {
			return (
				[
					date.getFullYear(),
					this.padSingleDigit(date.getMonth() + 1),
					this.padSingleDigit(date.getDate()),
				].join("-") +
				" " +
				[
					this.padSingleDigit(date.getHours()),
					this.padSingleDigit(date.getMinutes()),
					this.padSingleDigit(date.getSeconds()),
				].join("_") +
				`_${siteName}_${objName}`
			);
		},
		padSingleDigit(num) {
			return num.toString().padStart(2, "0");
		},
		lang(lang) {
			this.$i18n.locale = lang;
		},
		openAddDataDialog() {
			this.showAddDataDialog = true;
			this.showObjectDataDialog = false;
			this.showAddLineWorksDialog = false;
		},
		closeAddDataDialog() {
			this.showAddDataDialog = false;
		},
		openAddLineWorksDialog() {
			if (this.showViewerAgain) {
				this.showViewerAgain = false;
				this.resetCreationMode();
			}
			this.showAddDataDialog = false;
			this.showObjectDataDialog = false;
			this.showAddLineWorksDialog = true;
		},
		closeAddLineWorksDialog() {
			this.showAddLineWorksDialog = false;
		},
		openObjectDataDialog() {
			if (this.showViewerAgain) {
				this.showViewerAgain = false;
				this.resetCreationMode();
			}
			this.showAddDataDialog = false;
			this.showAddLineWorksDialog = false;
			this.showObjectDataDialog = true;
		},
		resetCreationMode() {
			if (this.isRoadAdd) this.cancelRoadCreation();
			if (this.isFlatAdd) this.cancelFlatGroundCreation();
			if (this.isTrenchAdd) this.cancelTrenchCreation();
			if (this.isArbitraryLineAdded) this.cancelArbitraryLineCreation();
		},
		closeObjectDataDialog() {
			this.showObjectDataDialog = false;
		},
		closeExclusivityNoticeDialog() {
			this.$store.commit("set_exclusion", false);
		},
		gotoList() {
			this.updateGlobeVisibility(true);
			window.location.href = "/";
		},
		deleteAllObjFromCesium() {
			this.$store.state.objects.forEach((item) => {
				if (item.type === 0) {
					window["viewer"].deleteRoad(item.id);
				} else {
					window["viewer"].removePolygon(item.id);
				}
			});
			this.$store.commit("initObj");
		},
		escapeKeyDown(event) {
			if (event.key == "Escape") {
				this.isRoadAdd = false;
				this.isFlatAdd = false;
				this.isTrenchAdd = false;
				this.isArbitraryLineAdded = false;
			}
		},
		changeDesignTransparency(design) {
			window["viewer"].updateTilesetTransparency(
				Number.parseInt(design.id),
				"design",
				design.transparency,
			);
		},
		toggleDesignVisibility(design) {
			design.isShow = !design.isShow;
			this.changeDesignTransparency(design);
			window["viewer"].updateTilesetVisibility(
				Number.parseInt(design.id),
				"design",
				design.isShow,
			);
		},
		async toggleMeasurementMode() {
			if (this.$store.state.modes.inEditDrawing2dImage.isActive) {
				this.$store.dispatch("end2dImageEdit");
			}
			this.disableAllEdits();
			window["viewer"].checkRoadUnderCreation() && this.cancelRoadCreation();
			window["viewer"].checkFlatGroundUnderCreation() &&
				this.cancelFlatGroundCreation();
			window["viewer"].checkArbitraryLineUnderCreation() &&
				this.cancelArbitraryLineCreation();
			this.disableAllEdits();
			try {
				// 2点距離を取得
				const distanceInfo = await window["viewer"].enableMeasurementCreation();
				// ローディング開始
				this.$store.dispatch("updateIsLoading", true);
				// 登録リクエスト
				const res = await axios.post(
					`${import.meta.env.VITE_API_BASE}/twoPointsPicker/${this.$route.query.siteId}`,
					{
						start_info: distanceInfo.start,
						end_info: distanceInfo.end,
					},
				);
				if (res.status === 201) {
					// データリスト更新
					await this.getMeasurementList(this.$route.query.siteId);
					this.set_snackbar({
						text: `${this.$t("CREATE_DISTANCE_POINTS")} ${this.$t("successful")}`,
						color: "rgba(0, 153, 0, 0.72)",
					});
				}
			} catch {
				this.set_snackbar({
					text: `${this.$t("CREATE_DISTANCE_POINTS")} ${this.$t("failed")}`,
					color: "rgba(153, 0, 0, 0.72)",
				});
			} finally {
				// ローディング終了
				this.$store.dispatch("updateIsLoading", false);
			}
		},
		async fetchTwoPointsList() {
			try {
				// ローディング開始
				this.$store.dispatch("updateIsLoading", true);
				await this.getMeasurementList(this.$route.query.siteId);
				this.set_snackbar({
					text: `${this.$t("GET_DATA")} ${this.$t("successful")}`,
					color: "rgba(0, 153, 0, 0.72)",
				});
			} catch {
				this.set_snackbar({
					text: `${this.$t("GET_DATA")} ${this.$t("failed")}`,
					color: "rgba(153, 0, 0, 0.72)",
				});
			} finally {
				// ローディング終了
				this.$store.dispatch("updateIsLoading", false);
			}
		},
		async toggleLineWorkCreationMode() {
			if (this.$store.state.modes.inEditDrawing2dImage.isActive) {
				this.$store.dispatch("end2dImageEdit");
			}
			this.disableAllEdits();
			window["viewer"].checkRoadUnderCreation() && this.cancelRoadCreation();
			window["viewer"].checkFlatGroundUnderCreation() &&
				this.cancelFlatGroundCreation();
			window["viewer"].checkArbitraryLineUnderCreation() &&
				this.cancelArbitraryLineCreation();
			this.$store.dispatch(
				"startCreationMode",
				constants.objectType.LINE_WORKS,
			);
			this.showViewerAgain = true;
			this.isArbitraryLineAdded = !this.isArbitraryLineAdded;
			try {
				const pointCloudId =
					this.$store.getters.activePointCloud?.getAssetIdNum();
				await window["viewer"].enableArbitraryLineCreation((viewerLineId) => {
					const event = new CustomEvent("openPropertyDialog", {
						detail: { id: viewerLineId, type: 9 },
					});
					window.dispatchEvent(event);
				}, pointCloudId);
			} catch (error) {
				window["viewer"].cancelArbitraryCreation();
				console.log(error);
			}
		},
		cancelArbitraryLineCreation() {
			this.$store.dispatch("endCreationMode");
			this.isArbitraryLineAdded = false;
			window["viewer"].cancelArbitraryCreation();
		},
		homeIconHandler() {
			if (this.measurementMode) {
				window["viewer"].disableMeasurementCreation();
			}
		},
		async get_site_info() {
			console.trace("get site info");
			const res = await axios.get(
				`${import.meta.env.VITE_API_BASE}/sites/${this.$route.query.siteId}`,
				{
					auth: this.$store.state.authObject,
				},
			);
			return res ? res.data : null;
		},
		receiveData(objData) {
			// 仮設道路追加
			if (objData === "road") this.roadCreation();
			// 平場追加
			else if (objData === "ground") this.flatGroundCreation();
			// 床掘追加
			else if (objData === "trench") this.trenchCreation();
			// AIによる道路追加
			else if (objData === "roadAI") this.addRoadByJson();
		},
		async setCoordinateSystemLabel() {
			if (this.siteInfo) {
				const res = await axios.get(
					`${import.meta.env.VITE_API_BASE}/geospatial/coordinateSystem/${this.siteInfo?.epsg}`,
				);
				this.epsgLabel = res.data.length
					? res.data[0].label
					: this.siteInfo.epsg;
			}
		},
		async setVerticalShiftLabel() {
			if (this.siteInfo) {
				const res = await axios.get(
					`${import.meta.env.VITE_API_BASE}/geospatial/verticalShift/${this.siteInfo?.epsg_v}`,
				);
				this.epsgvLabel = res.data.length
					? res.data[0].label
					: this.siteInfo.epsg_v;
			}
		},
		endCreateMode() {
			this.$store.dispatch("endCreationMode");
			this.isFlatAdd = false;
			this.isRoadAdd = false;
			this.isTrenchAdd = false;
			this.isArbitraryLineAdded = false;
		},
		cancelCreateMode() {
			this.$store.dispatch("endCreationMode");
			if (this.isRoadAdd) {
				this.cancelRoadCreation();
			} else if (this.isFlatAdd) {
				this.cancelFlatGroundCreation();
			} else if (this.isTrenchAdd) {
				this.cancelTrenchCreation();
			} else if (this.isArbitraryLineAdded) {
				this.cancelArbitraryLineCreation();
			}
		},
		async openDashboardSite() {
			// show sipnner
			this.$store.dispatch("updateIsLoading", true);
			try {
				// get site-url for Dashboard
				const response = await axios.get(
					`${import.meta.env.VITE_API_BASE}/dashboard/${this.$route.query.siteId}/link`,
				);
				// show the Dashboard site in new tab
				window.open(response.data);
			} catch (e) {
				let message = this.$t("MESSAGE_ERROR_DEFAULT");
				if (e.response?.status === 404) {
					message = this.$t("MESSAGE_ERROR_NOT_FOUND_DASHBOARD_SITE");
				}
				// show snackbar
				this.$store.commit("set_snackbar", {
					text: message,
					color: "rgba(153, 0, 0, 0.72)",
				});
			} finally {
				// hide spinner
				this.$store.dispatch("updateIsLoading", false);
			}
		},
	},
	async created() {
		try {
			window.addEventListener("keydown", this.escapeKeyDown);
			const res = await this.get_site_info();
			this.$store.commit("set_initial_site_datas", res);
		} catch (error) {
			console.error(error.message);
		}
	},
	unmounted() {
		console.log("destroy all road");
		this.deleteAllObjFromCesium();
		window.removeEventListener("keydown", this.escapeKeyDown);
		window.removeEventListener("endCreateMode", this.endCreateMode);
		window.removeEventListener("cancelCreateMode", this.cancelCreateMode);
		window.removeEventListener("disableAllEdits", this.disableAllEdits);
		this.set_modes({
			name: "ai_road_point_selection",
			value: false,
		});
		window["viewer"].removeAIRoadPreview();
	},
};
</script>

<style scoped>
.v-tab {
	min-width: 48px !important;
	width: 48px;
}

.left-area {
	width: 100%;
	/* 104px = 56px + 48px */
	height: calc(100vh - 104px);
}

.outer-link {
	margin-top: auto;
}

.panel-main {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.tab {
	height: 100%;
}

td {
	padding: 0px 8px 0px 8px !important;
}

.hover-blue:hover {
	color: #1976d2;
}

.highlight {
	background-color: #004f93 !important;
	border: #ffffff solid 1px;
}

.Doing {
	background-color: #0064ba !important;
	border: #0064ba solid 2px;
}

.Doing:hover {
	background-color: #0059a6 !important;
	border: #0059a6 solid 2px;
}

.list-doing:hover {
	background-color: #0064ba !important;
}

.list-delayed:hover {
	background-color: #b90007;
}

.v-btn--disabled.multiple-pc {
	pointer-events: unset !important;
}

.panel-main-item {
	height: 32px;
	padding: 0;
	margin-bottom: 8px;
}

.add-btn {
	justify-content: space-between;
	width: 250px;
	height: 100%;
	margin: auto;
	padding: 4px 16px;
}

.globe-switch {
	position: absolute;
	right: 10px;
}

.col-header {
	width: 40%;
}

.measure-btn {
	margin: 0px auto;
	white-space: normal !important;
	height: auto;
}

:deep(div.panel-main.measurement.v-card.v-sheet) {
	height: 100%;
}

:deep(.bg-panel_main) {
	padding-bottom: 16px;
}
</style>
