<script setup>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Drawing2dImage } from "../../models/Drawing2DImage";
import { removeDrawing2dImage } from "../../utils/cesium-common";
import validationRules from "@/utils/validation-rules.js";

/** props定義 */
const props = defineProps({
	entity: Drawing2dImage,
	draggable: Boolean,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();
const route = useRoute();

/**
 * Delete the 2D Image.
 */
const remove = async () => {
	store.dispatch("end2dImageEdit");
	// Update object list
	try {
		await store.dispatch("get_obj_list", route.query.siteId);
	} catch {
		store.commit("set_snackbar", {
			text: `${t("REFRESH_OBJECT_LIST")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}

	// Return the object that is associated assetId of the 2D image to be deleted
	const associateObject = store.state.objects.find((obj) => {
		const associateDrawing2dImageId =
			obj.json && JSON.parse(obj.json).associateDrawing2dImageId;
		return (
			associateDrawing2dImageId &&
			props.entity.getId() === associateDrawing2dImageId
		);
	});

	// Show the error message on the snackbar if the 2D image is associated any object
	if (associateObject) {
		store.commit("set_snackbar", {
			text: t("CANNOT_DELETE_ASSOCIATED_POINT_CLOUD"),
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}

	try {
		await store.dispatch("drawing2dImage/deleteDrawing2dImage", {
			siteId: route.query.siteId,
			drawing2dImage: props.entity,
		});

		removeDrawing2dImage(props.entity);

		// remove sucess
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("successful")}`,
			color: "rgba(0, 153, 0, 0.72)",
		});
	} catch (e) {
		// remove failed
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
		console.error(e);
		return;
	}
};
const rules = {
	...validationRules,
	required: (value) => !!value || "*",
};
</script>

<template>
  <span>
    <span v-if="timeStamp" class="ml-4">{{ timeStamp }}</span>
    <v-card-actions class="py-0" :style="isEditOnProgress ? 'background-color: #0064BA;' : ''">
      <v-btn v-if="draggable" icon size="24" class="handle" style="cursor: grab">
        <v-icon> mdi-drag </v-icon>
      </v-btn>
      <v-btn icon size="24" @click="toggle_visibility" :disabled="isDisabledVisibilityBtn" class="ml-0">
        <v-icon>
          {{ entity.getIsVisible() ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <v-btn icon size="36" @click="toggle_expansion" class="ml-0">
        <v-icon>
          mdi-play {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
        </v-icon>
      </v-btn>
      <ListItemLabel :text="entity?.getName() ?? ''" />
      <v-btn @click="focus" :disabled="isDisabledFocusBtn" icon size="24">
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <v-menu transition="slide-y-transition" :disabled="entity.getTilingProgress()">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="24">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item @click="edit">
            <v-icon class="mr-4">icon:Pencil</v-icon>
            <span style="font-size: 13px">{{ $t("EDIT") }}</span>
          </v-list-item>
          <v-list-item :disabled="!entity.isLoaded" @click="exportLandXML">
            <v-icon class="mr-4">icon:Export</v-icon>
            <span style="font-size: 13px">{{ $t("EXPORT_AS") }}</span>
          </v-list-item>
          <v-list-item @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <span style="font-size: 13px">{{ $t("REMOVE") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 処理ステータス -->
      <div v-if="entity.getProcessingStatus()">
        <processing-status :status="entity.getProcessingStatus()"
          :processingStatus="constants.pointCloudProcessingStatus">
        </processing-status>
      </div>
    </v-card-actions>
    <div class="pl-7 mb-2" v-show="entity.getExpansion()">
      <v-card-actions class="py-0 px-5">
        {{ $t("OPACITY") }}
        <v-slider class="ml-1" v-model="transparency" :disabled="isDisabledTransparency" @end="saveSettings"
          hide-details max="1" step="0.01" color="primary" track-size="0.5" thumb-size="14"></v-slider>
      </v-card-actions>
      <v-card-actions class="py-0 px-5 d-flex">
        <span class="mr-4 align-self-end">{{ $t("ELEVATION") }}</span>
        <v-form ref="inputElevation" @submit.prevent>
          <v-text-field variant="underlined" hide-details density="compact" type="number" min="-999999.999" step="0.001"
            max="999999.999" suffix="m" :disabled="!isEditOnProgress" @blur="onBlurElevation" v-model.number="elevation"
            :rules="[
              rules.orLess(999999.999),
              rules.orMore(-999999.999),
              rules.decimalLength(3),
              rules.numberFormat
            ]" class="flex-grow-1 align-self-center" :draggable="true" :ondragstart="e => e.preventDefault()"
            :onkeydown="e => e.code !== 'KeyE'" />
        </v-form>
      </v-card-actions>
    </div>
  </span>
</template>

<script>
import ProcessingStatus from "@/components/common/ProcessingStatus.vue";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import * as constants from "@/constant.js";
import * as cesiumCommon from "@/utils/cesium-common";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import ProgressCircle from "./ProgressCircle";

dayjs.extend(isSameOrAfter);

export default {
  name: "Drawing2dImageListItem",
  components: {
    ProgressCircle,
    ProcessingStatus,
    ListItemLabel
  },
  mounted() {
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false,
      constants,
      isEditOnProgress: false,
      elevation: NaN,
    };
  },
  computed: {
    isAssetUploaded() {
      return this.entity.getIsAssetUploaded();
    },
    /**
     * 画面のクリエーションモードの状態
     */
    inCreationMode() {
      return this.$store.state.modes.inCreationMode.isActive;
    },
    /**
     * 点群の透明度
     */
    transparency: {
      get() {
        return this.entity.getTransparency();
      },
      set(value) {
        this.entity.setTransparency(value);
      },
    },
    /**
     * 可視状態切り替えボタンの活性状態を制御する
     * 以下の場合非活性
     * ・道路追加や平場追加を実行中で他の点群が活性状態じゃない
     * ・タイリング途中
     * ・アセットがアップロードされていない
     */
    isDisabledVisibilityBtn() {
      return (
        (this.inCreationMode && !this.entity.getIsCreating()) ||
        this.entity.getTilingProgress() ||
        this.isAssetUploaded === "PENDING" ||
        this.isAssetUploaded === "ERROR"
      );
    },
    /**
     * 透明度スライダーの活性状態を制御する
     */
    isDisabledTransparency() {
      // 非表示状態の場合は非活性
      return !this.entity.getIsVisible();
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      return !this.entity.getIsVisible();
    },
    timeStamp() {
      return this.entity.getTimeStamp();
    },
    editTargetId() {
      return this.$store.state.modes.inEditDrawing2dImage.editTargetId;
    },
    inEditMode() {
      return this.$store.state.modes.inEditDrawing2dImage.isActive;
    },
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === "PENDING") {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
    transparency() {
      let transparency_val = parseFloat(this.transparency.toFixed(2));
      try {
        cesiumCommon.updateDrawing2dImageTransparency(
          this.entity,
          transparency_val
        );
      } catch (e) {
        console.error(e);
      }
    },
    inCreationMode(newCreationMode) {
      if (newCreationMode) {
        // 画面がクリエーションモードに変更になった場合
        if (this.entity.getIsVisible()) {
          // 可視状態であれば点群をクリエーションモードに変更
          this.entity.setIsCreating(true);
        }
      } else {
        // 画面のクリエーションモードが解除された場合
        if (this.entity.getIsCreating()) {
          // 点群がクリエーションモードであれば解除
          this.entity.setIsCreating(false);
        }
      }
    },
    editTargetId(newValue) {
      if (newValue && newValue !== this.entity.getId()) {
        this.isEditOnProgress = false;
      }
    },
    inEditMode(newValue) {
      if (!newValue && this.isEditOnProgress) {
        this.isEditOnProgress = false;
        this.$store.getters.viewer.end2PointsAlignment();
      }
    },
    elevation(newValue) {
      const integerPartMaxLength = newValue >= 0 ? 6 : 7;
      const inputs = newValue.toString().split(".");
      const integerPart = inputs[0].length > integerPartMaxLength ? inputs[0].slice(0, integerPartMaxLength) : inputs[0];
      const decimalPart = inputs[1]?.length > 3 ? inputs[1].slice(0, 3) : inputs[1];
      this.elevation = Number.isInteger(newValue) ? Number(integerPart) : parseFloat(`${integerPart}.${decimalPart}`);
    },
  },
  methods: {
    async toggle_visibility() {
      await cesiumCommon.toggleDrawing2dImageVisibility(this.entity);
      if (!this.entity.getIsVisible() && this.isEditOnProgress) {
        // if editing image is to be invisible, finish editing
        this.isEditOnProgress = false;
        this.$store.dispatch("end2dImageEdit");
        this.$store.getters.viewer.end2PointsAlignment();
      }
      // visibility is changed to be visible
      if (this.entity.getIsVisible()) {
        this.elevation = parseFloat(this.entity.getElevation().toFixed(3));
      }
    },
    toggle_expansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },
    focus() {
      cesiumCommon.zoomToDrawing2dImage(this.entity);
    },
    saveSettings() {
      cesiumCommon.uploadDrawing2dImage(this.entity.getId(), this.$route.query.siteId);
    },
    async edit() {
      this.isEditOnProgress = !this.isEditOnProgress;
      if (this.isEditOnProgress) {
        if (this.editTargetId && this.editTargetId !== this.entity.getId()) {
          // if editing other image, finish editing once
          this.$store.getters.viewer.end2PointsAlignment();
        }
        if (!this.entity.getIsVisible()) {
          // if image is invisible, display it
          await this.toggle_visibility();
        }
        this.elevation = parseFloat(this.entity.getElevation().toFixed(3));
        this.$store.dispatch("start2dImageEdit", this.entity.getId());
        this.$store.getters.viewer.start2PointsAlignment(this.entity.getId(), {
          onError: (e) => {
            console.warn(e);
            this.$store.commit("set_snackbar", {
              text: `${this.$t("EDIT")} ${this.$t("failed")}`,
              color: "rgba(153, 0, 0, 0.72)",
            });
          },
          onCancel: () => {
            this.isEditOnProgress = false;
          },
          onProgress: () => {
            // Todo update on the server 
            console.log("drawing on Progress");
            cesiumCommon.uploadDrawing2dImage(this.entity.getId(), this.$route.query.siteId);
          }
        });
      }
      else {
        this.$store.dispatch("end2dImageEdit");
        if (!await this.validateElevation()) {
          // Write back to previous saved value if invalid elevation value
          this.elevation = this.entity.getElevation();
        }
        this.$store.getters.viewer.end2PointsAlignment();
      }
    },
    async exportLandXML() {
      const geometry = this.$store.getters.viewer.getDrawing2DImageMeshGeometry(this.entity.getId());
      const event = new CustomEvent("openPropertyDialog", {
        detail: { type: 6, name: this.entity.getName(), geometry },
      });
      window.dispatchEvent(event);
    },
    async onBlurElevation() {
      const isValidElevation = await this.validateElevation();
      if (isValidElevation && this.elevation !== this.entity.getElevation()) {
        // Update elevation only if validation is success and elevation is changed value
        this.entity.setElevation(this.elevation);
        await this.$store.getters.viewer.setElevationOfDrawing2dImage(this.entity.getId(), this.elevation);
        cesiumCommon.uploadDrawing2dImage(this.entity.getId(), this.$route.query.siteId);
      }
    },
    async validateElevation() {
      const validate = await this.$refs.inputElevation.validate();
      // Return true if no validation error, otherwise false
      return validate.valid;
    },
  },
  beforeUnmount() {
    // 仮設道路追加や平場追加を行っている場合キャンセル
    if (this.inCreationMode) {
      window.dispatchEvent(new Event("cancelCreateMode"));
    }
    if (this.inEditMode) {
      // if editing 2D image, finish editing
      this.$store.dispatch("end2dImageEdit");
    }
  },
};
</script>
