<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      {{ $t("SELECT_DATA_TYPE") }}
      <v-btn icon variant="flat" @click="close()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-card-text>{{ this.defaultFileInfo?.name }}</v-card-text>
      <v-container v-if="isShow(SELECT_TYPE.POINT_CLOUD)" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Mountain </v-icon>
                </v-col>
                <v-col cols="10" @click="openTerrainDataDialog('pointcloud')">
                  <v-card-title class="card-btn-title"> {{ $t("POINT_CLOUD") }} </v-card-title>
                  <v-card-text>LASer(.las), CSV(.txt, .csv)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="isShow(SELECT_TYPE.DESIGN)" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon"> <v-icon size="34"> icon:Cubes </v-icon></v-col>
                <v-col cols="10" @click="openTerrainDataDialog('design')">
                  <v-card-title class="card-btn-title"> {{ $t("DESIGN") }} </v-card-title>
                  <v-card-text>LandXML(.xml), DXF(.dxf)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="isDrawing2dImage && isShow(SELECT_TYPE.DRAWING_2D)" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon"> <v-icon size="34"> mdi-drawing-box </v-icon></v-col>
                <v-col cols="10" @click="openTerrainDataDialog('drawing2dImage')">
                  <v-card-title class="card-btn-title"> {{ $t("DRAWING_2D_IMAGE") }} </v-card-title>
                  <v-card-text>Image(.jpeg, .jpg, .png)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="isDrawing2dImage" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon"> <v-icon size="34"> mdi-drawing-box </v-icon></v-col>
                <v-col cols="10" @click="createDrawing2dImage()">
                  <v-card-title class="card-btn-title"> {{ $t("CREATE_REFERENCE_TOPOLOGY") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="isShow(SELECT_TYPE.LINEWORKS)" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Cubes </v-icon>
                </v-col>
                <v-col cols="10" @click="openTerrainDataDialog('lineworks')">
                  <v-card-title class="card-btn-title"> {{ $t("LINEWORKS") }} </v-card-title>
                  <!-- TODO: 多言語化 -->
                  <v-card-text>DXF(.dxf)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="isImportFromGroupware && isShow(SELECT_TYPE.GROUPWARE)" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Cubes </v-icon>
                </v-col>
                <v-col cols="10" @click="openGroupwareFileSelectorDialog">
                  <v-card-title class="card-btn-title"> {{ $t("IMPORT_FROM_GROUPWARE") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="SELECT_TYPE.DASHBOARD_ALIGNMENT" class="type-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon size="34"> icon:Export</v-icon>
                </v-col>
                <v-col cols="10" @click="openExportFromDashboardDialog">
                  <v-card-title class="card-btn-title"> {{ $t("LOCALIZATION_FILE") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

const SELECT_TYPE = Object.freeze({
	POINT_CLOUD: "point_cloud",
	DESIGN: "design",
	DRAWING_2D: "drawing_2d",
	LINEWORKS: "lineworks",
	GROUPWARE: "groupware",
	DASHBOARD_ALIGNMENT: "dashboard_alignment",
});

export default {
	name: "SelectType",
	props: {
		id: String,
		defaultFileInfo: String, // this is defined when a user try to import file from groupware
	},
	mounted: () => {},
	data: () => ({
		showTerrainDataDialog: false,
		SELECT_TYPE,
		isDrawing2dImage: window.featureFlag.drawing2dImage, // Feature flag. This will be removed.
		isImportFromGroupware: window.featureFlag.importFromGroupware, // Feature flag. This will be removed.
	}),
	watch: {},
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions(["get_point_cloud_list", "get_design_list"]),
		isShow(selectType) {
			if (!this.defaultFileInfo) return true;
			const showTypes = [
				SELECT_TYPE.POINT_CLOUD,
				SELECT_TYPE.DESIGN,
				SELECT_TYPE.LINEWORKS,
				SELECT_TYPE.DASHBOARD_ALIGNMENT,
			];
			return showTypes.includes(selectType);
		},
		close() {
			this.$emit("close");
		},
		openExportFromDashboardDialog() {
			const dummyId = 1;
			const event = new CustomEvent("openPropertyDialog", {
				detail: { id: dummyId, type: 8 },
			});
			window.dispatchEvent(event);
			this.close();
		},
		openGroupwareFileSelectorDialog() {
			const type = "groupware";
			this.$emit("open-terrain-data", type);
		},
		openTerrainDataDialog(type) {
			switch (type) {
				case "pointcloud": {
					const allowedFileTypes = ".txt,.las,.csv";
					const axisShow = true;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
				case "design": {
					const allowedFileTypes = ".xml,.dxf";
					const axisShow = false;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
				case "lineworks": {
					const allowedFileTypes = ".dxf";
					const axisShow = false;
					this.$emit("open-terrain-data", type, allowedFileTypes, axisShow);
					break;
				}
				case "drawing2dImage": {
					const allowedFileTypes = ".jpeg,.jpg,.png";
					const axisShow = false;
					const isDrawing2dImage = true;
					this.$emit(
						"open-terrain-data",
						type,
						allowedFileTypes,
						axisShow,
						isDrawing2dImage,
					);
					break;
				}
			}
		},
		createDrawing2dImage() {
			this.close();
			const onConstructionFinish = (data) => {
				// this.$emit("open-terrain-data", "drawing2dImage", "", false, true, true,{wgs84, local});
				const event = new CustomEvent("createDrawing2dImage", { detail: data });
				window.dispatchEvent(event);
			};
			window["viewer"].startDrawing2DImageConstruction({
				onFinish: onConstructionFinish.bind(this),
				onCancel: () => {
					console.log("Reference Topology Construction cancelled");
				},
				onError: () => {
					console.log("Reference Topology Construction error");
				},
			});
		},
	},
};
</script>

<style scoped>
.card-btn {
  display: flex;
  align-items: center;
  height: 110px;
}

.card-btn:hover {
  background-color: #2e2e2e;
}

.card-btn-title {
  white-space: normal
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.type-select-btn {
  width: 90%;
}
</style>
