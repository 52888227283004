import axios from "axios";
import store from "@/store/index";

/**
 * Fetches raw nodes data from the specified site and bucket.
 *
 * @param {string} siteId - The ID of the site.
 * @param {string} bucketId - The ID of the bucket.
 * @param {Object} queryParameters - The query parameters to be included in the request.
 * @returns {Promise<Object>} The response data from the API.
 * @throws {Error} If the request fails.
 */
export const getNodesRaw = async (siteId, bucketId, queryParameters) => {
	const url = `${import.meta.env.VITE_API_BASE}/assetLink/groupware/${siteId}/buckets/${bucketId}`;
	try {
		const response = await axios.get(url, {
			auth: store.state.authObject,
			params: queryParameters,
		});
		return response.data;
	} catch (e) {
		throw new Error("Failed to get preconstruction nodes.");
	}
};

/**
 * Fetches the bucket information for a given site.
 *
 * @param {string} siteId - The ID of the site for which to fetch the bucket.
 * @param {Object} queryParameters - The query parameters to include in the request.
 * @returns {Promise<Object>} The response from the API containing the bucket information.
 * @throws {Error} If the request fails.
 */
export const getBuckets = async (siteId, queryParameters) => {
	const url = `${import.meta.env.VITE_API_BASE}/assetLink/groupware/${siteId}/buckets`;
	try {
		const response = await axios.get(url, {
			auth: store.state.authObject,
			params: queryParameters,
		});
		return response.data;
	} catch (e) {
		throw new Error("Failed to get preconstruction bucket.");
	}
};
