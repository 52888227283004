<template>
    <v-expand-transition>
        <div v-show="obj.isOpen">
            <v-form v-model="isValid" ref="form">
                <v-card-text class="py-0 pr-0">
                    <!-- 道路・摺り付け -->
                    <v-card-actions class="pr-0">
                        {{ $t("Roadparameter") }}
                        <v-btn class="ml-auto" height="20px" icon @click="togglePanel(0)">
                            <v-icon>
                                {{
                                    isPanelActive(0)
                                    ? "icon:Chevron-Up"
                                    : "icon:Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="isPanelActive(0)">
                            <v-table density="compact">
                                <tbody>
                                    <!-- 道路長さ -->
                                    <tr>
                                        <td>
                                            {{ $t("Lengthofroad") }}
                                        </td>
                                        <td>{{ obj.info.length.toFixed(3) }}m</td>
                                    </tr>
                                    <!-- 道路幅左 -->
                                    <tr>
                                        <td>{{ $t("Widthofroad") }} {{ $t("Left") }}</td>
                                        <td>
                                            <v-text-field  variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="!obj.edit" @blur="onBlur"
                                                v-model.number="obj.settings.commonSettings.leftWidth" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 道路幅右 -->
                                    <tr>
                                        <td>
                                            <span style="opacity: 0">{{
                                                $t("Widthofroad")
                                            }}</span>
                                            {{ $t("Right") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="!obj.edit" @blur="onBlur"
                                                v-model.number="obj.settings.commonSettings.rightWidth" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 道路範囲 -->
                                    <tr>
                                        <td>
                                            {{ $t("Elevationarea") }}
                                        </td>
                                        <td>
                                            {{ obj.info.height.min.toFixed(3) }}m -
                                            {{ obj.info.height.max.toFixed(3) }}m
                                        </td>
                                    </tr>
                                    <!-- 勾配範囲 -->
                                    <tr>
                                        <td>
                                            {{ $t("Gradientarea") }}
                                        </td>
                                        <td>
                                            <SlopeRangeComponent :min="obj.info.slope.min" :max="obj.info.slope.max"
                                                :suffix="getSuffix(roadParameterSlopeUnit.gradientArea)">
                                            </SlopeRangeComponent>
                                        </td>
                                    </tr>
                                    <!-- 片勾配にする -->
                                    <tr>
                                        <td>{{ $t("Setsuperelevation") }}</td>
                                        <td>
                                            <v-select variant="plain" v-model="obj.settings.commonSettings.isSuperElevation"
                                                :items="slopeTypes" item-title="label" item-value="value" density="compact" hide-details
                                                :disabled="!obj.edit" class="ma-0"
                                                v-on:update:model-value="onBlur"></v-select>
                                        </td>
                                    </tr>
                                    <!-- 片面勾配 -->
                                    <tr v-if="obj.settings.commonSettings.isSuperElevation">
                                        <td>
                                            {{ $t("Superelevation") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(roadParameterSlopeUnit.superElevation)"
                                                :is-editable="obj.edit"
                                                :slope-value="obj.settings.commonSettings.leftSlope" :step="0.01"
                                                @blur="onBlur"
                                                v-model.number="obj.settings.commonSettings.leftSlope"
                                                :max-value="roadParameterSlopeUnit.superElevation === 'percent' ? 9999.99 : 999.99"
                                                :min-value="roadParameterSlopeUnit.superElevation === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="roadParameterSlopeUnit.superElevation === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 横断勾配 -->
                                    <tr v-else>
                                        <td>
                                            {{ $t("Crossslope") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(roadParameterSlopeUnit.crossSlope)"
                                                :is-editable="obj.edit"
                                                :slope-value="obj.settings.commonSettings.leftSlope" :step="0.01"
                                                @blur="onBlur"
                                                v-model.number="obj.settings.commonSettings.leftSlope"
                                                :max-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 9999.99 : 999.99"
                                                :min-value="roadParameterSlopeUnit.crossSlope === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="roadParameterSlopeUnit.crossSlope === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 摺り付け点群 -->
                                    <tr v-if="pointClouds.length > 0">
                                        <td>{{ $t("ASSOCIATED_POINT_CLOUD") }}</td>
                                        <td>
                                          <v-select 
                                            variant="plain"
                                            v-model="json.associatePointCloudId"
                                            :items="pointClouds"
                                            :item-title="item => item.getName()"
                                            :item-value="item => item.getAssetIdNum()"
                                            density="compact"
                                            hide-details
                                            :disabled="!obj.edit"
                                            class="ma-0"
                                            @update:model-value="updateAssociatedPointCloud()">
                                          </v-select>
                                        </td>
                                    </tr>
                                    <!-- 摺り付けピッチ -->
                                    <tr>
                                        <td>
                                            {{ $t("InterpolationPitch") }}
                                            <span class="text-red-lighten-3">*</span>
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                model-value="5" :disabled="!obj.edit" @blur="onBlur"
                                                v-model.number="obj.settings.commonSettings.interpolationPitch" :rules="[
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                ]" />
                                        </td>
                                    </tr>
                                    <!-- 路面色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadsurfacecolor") }}
                                        </td>
                                        <td>
                                                <v-menu :disabled="!obj.edit">
                                                    <template v-slot:activator="{ props }">
                                                        <v-avatar :color="obj.settings.commonSettings.surfaceColor"
                                                            size="20px" v-bind="props">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" 
                                                        v-model="obj.settings.commonSettings.surfaceColor"></v-color-picker>
                                                </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <IndividualSegmentSettingList v-show="obj.settings.commonSettings.segmentSettings.length > 0"
                                style="width: 100%;" :roadId="obj.id" :is-editable="obj.edit"
                                :suffix="getSuffix(roadParameterSlopeUnit.gradientArea)"
                                :setting-list="obj.settings.commonSettings.segmentSettings"></IndividualSegmentSettingList>
                        </div>
                    </v-expand-transition>
                    <!-- 切土設定 -->
                    <v-card-actions class="pr-0">
                        {{ $t("Cutsetting") }}
                        <v-btn class="ml-auto" height="20px" icon @click="togglePanel(1)">
                            <v-icon>
                                {{
                                    isPanelActive(1)
                                    ? "icon:Chevron-Up"
                                    : "icon:Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="isPanelActive(1)">
                            <v-table density="compact">
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="cutSlopeCondition" 
                                                :rules="
                                                  cutSlopeCondition ? [] :
                                                  [
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                  ]" v-model.number="obj.settings.cutSettings.roadHeight"
                                                @blur="onBlur" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <!-- 法面勾配 -->
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                :is-editable="obj.edit" :slope-value="obj.settings.cutSettings.moundSlope"
                                                :step="0.01" @blur="onBlur"
                                                v-model.number="obj.settings.cutSettings.moundSlope"
                                                :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="cutSlopeCondition"
                                                :rules="
                                                  cutSlopeCondition ? [] :
                                                  [
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                  ]" v-model.number="obj.settings.cutSettings.shelfWidth"
                                                @blur="onBlur" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                :is-editable="!cutSlopeCondition"
                                                :slope-value="obj.settings.cutSettings.shelfSlope" :step="0.01"
                                                @blur="onBlur"
                                                v-model.number="obj.settings.cutSettings.shelfSlope"
                                                :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2"
                                                :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("LeftRightSettings") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                                                @update:model-value="updateSettings()"
                                                v-model="obj.settings.cutSettings.bLeftRightSettings"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                                                @update:model-value="updateSettings()"
                                                v-model="obj.settings.cutSettings.generateShelf"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 道路色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadcolor") }}
                                        </td>
                                        <td>
                                                <v-menu :disabled="!obj.edit">
                                                    <template v-slot:activator="{ props }">
                                                        <v-avatar :color="obj.settings.cutSettings.color" size="20px"
                                                            v-bind="props">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" 
                                                        v-model="obj.settings.cutSettings.color"></v-color-picker>
                                                </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <!-- 左右設定 -->
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="py-0 px-2">
                                        {{ $t("Leftrightsetting") }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text class="pa-0">
                                        <v-row class="pa-0">
                                            <v-col class="pa-0">
                                                {{ $t("Leftcutsetting") }}
                                                <v-table density="compact">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition"
                                                                    :rules="
                                                                      cutLeftRightCondition ? [] :
                                                                      [
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.cutSettings.leftSlopeHeight"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(obj.edit && obj.settings.cutSettings.bLeftRightSettings)"
                                                                    :slope-value="obj.settings.cutSettings.leftMoundSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.cutSettings.leftMoundSlope"
                                                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition" 
                                                                    :rules="
                                                                      cutLeftRightCondition ? [] :
                                                                      [
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.cutSettings.leftShelfWidth"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!cutLeftRightCondition"
                                                                    :slope-value="obj.settings.cutSettings.leftShelfSlope"
                                                                    @blur="onBlur" :step="0.01"
                                                                    v-model.number="obj.settings.cutSettings.leftShelfSlope"
                                                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-table>
                                                {{ $t("Rightcutsetting") }}
                                                <v-table density="compact" style="background-color: rgba(41, 41, 47, 0)">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition" 
                                                                    :rules="
                                                                      cutLeftRightCondition ? [] :
                                                                      [
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.cutSettings.rightSlopeHeight"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(obj.edit && obj.settings.cutSettings.bLeftRightSettings)"
                                                                    :slope-value="obj.settings.cutSettings.rightMoundSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.cutSettings.rightMoundSlope"
                                                                    :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="cutLeftRightCondition"
                                                                    :rules="
                                                                      cutLeftRightCondition ? [] :
                                                                      [
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.cutSettings.rightShelfWidth"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!cutLeftRightCondition"
                                                                    :slope-value="obj.settings.cutSettings.rightShelfSlope"
                                                                    @blur="onBlur" :step="0.01"
                                                                    v-model.number="obj.settings.cutSettings.rightShelfSlope"
                                                                    :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-table>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-expand-transition>
                    <!-- 盛土設定 -->
                    <v-card-actions class="pr-0">
                        {{ $t("Fillsetting") }}
                        <v-btn class="ml-auto" height="20px" icon @click="togglePanel(2)">
                            <v-icon>
                                {{
                                    isPanelActive(2)
                                    ? "icon:Chevron-Up"
                                    : "icon:Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="isPanelActive(2)">
                            <v-table density="compact">
                                <tbody>
                                    <!-- 法面高さ -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopeheight") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="embSlopeCondition"
                                                :rules="
                                                  embSlopeCondition ? [] :
                                                  [
                                                    rules.required,
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                  ]" v-model.number="obj.settings.embarkmentSettings.roadHeight"
                                                @blur="onBlur" />
                                        </td>
                                    </tr>
                                    <!-- 法面勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Slopegradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                :is-editable="!(!obj.edit || obj.settings.embarkmentSettings.bLeftRightSettings)"
                                                :slope-value="obj.settings.embarkmentSettings.moundSlope" :step="0.01"
                                                @blur="onBlur"
                                                v-model.number="obj.settings.embarkmentSettings.moundSlope"
                                                :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                                            </SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <!-- 小段幅 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermwidth") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.01" suffix="m"
                                                :disabled="embSlopeCondition"
                                                :rules="
                                                  embSlopeCondition ? [] :
                                                  [
                                                    rules.orLess(99.99),
                                                    rules.orMore(0.01),
                                                    rules.decimalLength(2),
                                                    rules.numberFormat
                                                  ]" v-model.number="obj.settings.embarkmentSettings.shelfWidth"
                                                @blur="onBlur" />
                                        </td>
                                    </tr>
                                    <!-- 小段勾配 -->
                                    <tr>
                                        <td>
                                            {{ $t("Bermgradient") }}
                                        </td>
                                        <td>

                                            <SlopeSettingInput
                                                :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                :is-editable="!embSlopeCondition"
                                                :slope-value="obj.settings.embarkmentSettings.shelfSlope" :step="0.01"
                                                @blur="onBlur"
                                                v-model.number="obj.settings.embarkmentSettings.shelfSlope"
                                                :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                :decimal-length="2" :un-valid-zero="unit === 'percent'"></SlopeSettingInput>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("LeftRightSettings") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                                                @update:model-value="updateSettings()"
                                                v-model="obj.settings.embarkmentSettings.bLeftRightSettings"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 小段あり -->
                                    <tr>
                                        <td>
                                            {{ $t("Thereisberm") }}
                                        </td>
                                        <td>
                                            <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                                                @update:model-value="updateSettings()"
                                                v-model="obj.settings.embarkmentSettings.generateShelf"></v-checkbox>
                                        </td>
                                    </tr>
                                    <!-- 道路色 -->
                                    <tr>
                                        <td>
                                            {{ $t("Roadcolor") }}
                                        </td>
                                        <td>
                                                <v-menu :disabled="!obj.edit">
                                                    <template v-slot:activator="{ props }">
                                                        <v-avatar :color="obj.settings.embarkmentSettings.color"
                                                            size="20px" v-bind="props">
                                                        </v-avatar>
                                                    </template>
                                                    <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders
                                                        show-swatches mode="hexa" 
                                                        v-model="obj.settings.embarkmentSettings.color"></v-color-picker>
                                                </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                            <!-- 左右設定 -->
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="py-0 px-2">
                                        {{ $t("Leftrightsetting") }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text class="pa-0 pt-2">
                                        <v-row class="pa-0">
                                            <v-col class="pa-0">
                                                {{ $t("Leftfillsetting") }}
                                                <v-table density="compact">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m" 
                                                                    :disabled="embLeftRightCondition"
                                                                    :rules="
                                                                      embLeftRightCondition ? [] :
                                                                      [
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]" 
                                                                    @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.leftSlopeHeight" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(obj.edit && obj.settings.embarkmentSettings.bLeftRightSettings)"
                                                                    :slope-value="obj.settings.embarkmentSettings.leftMoundSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.leftMoundSlope"
                                                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="embLeftRightCondition" 
                                                                    :rules="
                                                                      embLeftRightCondition ? [] :
                                                                      [
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.embarkmentSettings.leftShelfWidth"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!embLeftRightCondition"
                                                                    :slope-value="obj.settings.embarkmentSettings.leftShelfSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.leftShelfSlope"
                                                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-table>
                                                {{ $t("Rightfillsetting") }}
                                                <v-table density="compact" style="background-color: rgba(41, 41, 47, 0);">
                                                    <tbody>
                                                        <!-- 法面高さ -->
                                                        <tr>
                                                            <td style="width: 42%">
                                                                {{ $t("Slopeheight") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m" 
                                                                    :disabled="embLeftRightCondition"
                                                                    :rules="
                                                                      embLeftRightCondition ? [] :
                                                                      [
                                                                        rules.required,
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]" 
                                                                    @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.rightSlopeHeight" />
                                                            </td>
                                                        </tr>
                                                        <!-- 法面勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Slopegradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                                                                    :is-editable="(obj.edit && obj.settings.embarkmentSettings.bLeftRightSettings)"
                                                                    :slope-value="obj.settings.embarkmentSettings.rightMoundSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.rightMoundSlope"
                                                                    :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="0.01" :decimal-length="2"
                                                                    :un-valid-zero="false"></SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                        <!-- 小段幅 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermwidth") }}
                                                            </td>
                                                            <td>
                                                                <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0"
                                                                    step="0.01" suffix="m"
                                                                    :disabled="embLeftRightCondition"
                                                                    :rules="
                                                                      embLeftRightCondition ? [] :
                                                                      [
                                                                        rules.orLess(99.99),
                                                                        rules.orMore(0.01),
                                                                        rules.decimalLength(2),
                                                                        rules.numberFormat
                                                                      ]"
                                                                    v-model.number="obj.settings.embarkmentSettings.rightShelfWidth"
                                                                    @blur="onBlur" />
                                                            </td>
                                                        </tr>
                                                        <!-- 小段勾配 -->
                                                        <tr>
                                                            <td>
                                                                {{ $t("Bermgradient") }}
                                                            </td>
                                                            <td>

                                                                <SlopeSettingInput
                                                                    :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                                                                    :is-editable="!embLeftRightCondition"
                                                                    :slope-value="obj.settings.embarkmentSettings.rightShelfSlope"
                                                                    :step="0.01" @blur="onBlur"
                                                                    v-model.number="obj.settings.embarkmentSettings.rightShelfSlope"
                                                                    :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                                                    :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                                                    :decimal-length="2"
                                                                    :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'">
                                                                </SlopeSettingInput>

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </v-table>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-expand-transition>
                    <!-- 土量 -->
                    <v-card-actions class="pr-0" v-show="config.useSoilVolumeCalculation">
                        {{ $t("Soilvolume") }}
                        <v-btn height="20px" icon :disabled="!obj.edit" @click="calculateRoadVolume(obj)" class="ml-3"
                            title="ボリュームを計算する" :loading="obj.loadingVolume">
                            <v-icon>
                                mdi-play-circle-outline
                            </v-icon>
                        </v-btn>
                        <v-btn class="ml-auto" height="20px" icon @click="togglePanel(3)">
                            <v-icon>
                                {{
                                    isPanelActive(3)
                                    ? "icon:Chevron-Up"
                                    : "icon:Chevron-Down"
                                }}
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-show="isPanelActive(3)">
                            <v-table density="compact">
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ $t("Cut") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.cutVolume" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {{ $t("FILL") }}
                                        </td>
                                        <td>
                                            <v-text-field variant="underlined"  hide-details density="compact" type="number" min="0" step="0.1"
                                                :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.fillVolume" />
                                        </td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </div>
                    </v-expand-transition>
                    <v-card-actions class="py-0 mr-3">
                        {{ $t("OPACITY") }}
                        <v-slider @end="saveChanges" v-model="obj.transparency" :disabled="!obj.edit"
                        hide-details max="1" step="0.01" class="ml-1"  color="primary" track-size="0.5" thumb-size="14"></v-slider>
                </v-card-actions>
            </v-card-text>
        </v-form>

    </div>
</v-expand-transition></template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import SlopeSettingInput from "../SlopeSettingInput.vue";
import IndividualSegmentSettingList from "../IndividualSegmentSettingList.vue";
import SlopeRangeComponent from "../SlopeRangeComponent.vue";
import {
	uploadObjAndNotify,
	updateRoadSettingsInViewer,
} from "../../../utils/cesium-common";
import * as constants from "@/constant.js";
import * as cesiumCommon from "@/utils/cesium-common";
import { UnitTypes } from "../../../constant";
import { useObjectPanel } from "./composables/useObjectPanel";

export default {
	setup(props) {
		const { obj, isValid, form, saveChanges } = useObjectPanel(props.objId);
		return {
			obj,
			isValid,
			form,
			saveChanges,
		};
	},
	name: "RoadPanel",
	components: {
		SlopeSettingInput,
		IndividualSegmentSettingList,
		SlopeRangeComponent,
	},
	props: {
		objId: String,
		rules: Object,
	},
	computed: {
		pointClouds() {
			return this.$store.state.point_cloud_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		roadParameterSlopeUnit() {
			return this.$store.state.roadParameterSlopeUnit;
		},
		cutSettingSlopeUnit() {
			return this.$store.state.cutSettingSlopeUnit;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
		slopeTypes() {
			return [
				{
					label: this.$t("Crossslope"),
					value: false,
				},
				{
					label: this.$t("Superelevation"),
					value: true,
				},
			];
		},
		// Disabling condition for cut setting's - shelfSlope, shelfWidth
		cutSlopeCondition() {
			return (
				!this.obj.edit ||
				this.obj.settings.cutSettings.bLeftRightSettings ||
				!this.obj.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		cutLeftRightCondition() {
			return !(
				this.obj.edit &&
				this.obj.settings.cutSettings.bLeftRightSettings &&
				this.obj.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for embankment setting's - shelfSlope, shelfWidth
		embSlopeCondition() {
			return (
				!this.obj.edit ||
				this.obj.settings.embarkmentSettings.bLeftRightSettings ||
				!this.obj.settings.embarkmentSettings.generateShelf
			);
		},
		// Disabling condition for embankment setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		embLeftRightCondition() {
			return !(
				this.obj.edit &&
				this.obj.settings.embarkmentSettings.bLeftRightSettings &&
				this.obj.settings.embarkmentSettings.generateShelf
			);
		},
	},
	data() {
		return {
			config,
			activePanel: null,
			json: {},
		};
	},
	methods: {
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		isNum(val) {
			return typeof val == "number";
		},
		async onBlur() {
			this.$store.dispatch("startObjectUpdating");
			await this.updateSettings();
		},
		updateSettings() {
			return this.$nextTick(async () => {
				this.$refs.form.validate();
				if (!this.isValid) return;
				await updateRoadSettingsInViewer(this.obj.id, this.obj);
				await uploadObjAndNotify(this.obj, constants.objectType.ROAD);
			});
		},
		initializeJson() {
			this.json = JSON.parse(this.obj.json);
		},
		updateJsonSettings() {
			this.obj.json = JSON.stringify(this.json);
		},
		async updateAssociatedPointCloud() {
			let hasUpdateError = false;
			try {
				// viewerの摺り付け点群を更新
				await cesiumCommon.updatePointCloudAssociatedWithObj(
					this.obj.id,
					this.json.associatePointCloudId,
					constants.objectType.ROAD,
				);
				// roadオブジェクトのjsonを更新
				this.updateJsonSettings();
				// データ削除区分
				this.$emit("road-panel-data", true);
			} catch {
				hasUpdateError = true;
				// エラーが発生したらJSONを元に戻す
				this.initializeJson();
			}
			// 変更内容を保存
			if (!hasUpdateError)
				uploadObjAndNotify(this.obj, constants.objectType.ROAD);
		},
		isPanelActive(id) {
			return this.activePanel === id;
		},
		togglePanel(id) {
			if (this.activePanel === id) {
				this.activePanel = null; // アクティブなアコーディオンを閉じる
			} else {
				this.activePanel = id; // 新しいアコーディオンをアクティブにする
			}
		},
		async calculateRoadVolume(obj) {
			obj.loadingVolume = true;
			try {
				const res = window["viewer"].volumeCalculationForRoad(obj.id);
				obj.cutVolume = res.cutVolume.toFixed(3);
				obj.fillVolume = res.fillVolume.toFixed(3);
			} catch (error) {
				console.error(error.message);
			} finally {
				obj.loadingVolume = false;
			}
			this.$forceUpdate();
		},
		unit() {
			return this.$store.state.slopeUnit;
		},
	},
	mounted() {
		this.initializeJson();
	},
};
</script>

<style scoped>
tr td {
    padding: 0 8px !important;
}
tr td:first-child {
    min-width: 84px;
}
</style>
