<template>
	<v-dialog v-model="showGetFromGroupwareDialog" width="77rem" persistent>
		<add-data-from-groupware @close="closeGetFromGroupwareDialog" @accept="onAccept" />
	</v-dialog>
	<v-dialog :scrim="false" v-model="showSelectTypeDialog" width="40%">
		<select-type :defaultFileInfo="this.selectedFiles[0]" @close="closeSelectTypeDialog"
			@open-terrain-data="openTerrainData" />
	</v-dialog>
	<v-dialog v-model="showTerrainDataDialog" width="600px" persistent>
		<add-terrain-data ref="add_terrain_data" @upload="uploadTerrainData" @close="closeTerrainData"
			:allowedFileTypes="allowedFileTypes" :axis_show="axisShow" :is_show="showTerrainDataDialog"
			:default_file_name="this.selectedFiles[0].name" :is_drawing_2d_image="isDrawing2dImage" />
	</v-dialog>
</template>

<script>
import addDataFromGroupware from "../AddData/addDataFromGroupware.vue";
import SelectType from "../AddData/SelectType.vue";
import addTerrainData from "../AddData/addTerrainData.vue";
import { mapMutations } from "vuex";
import axios from "axios";

const STATES = {
	SELECT_FROM_GROUPWARE: "SELECT_FROM_GROUPWARE",
	SELECT_TYPE: "SELECT_TYPE",
	ADD_TERRAIN_DATA: "ADD_TERRAIN_DATA",
	CLOSE_ALL: "CLOSE_ALL",
};

class State {
	constructor() {
		this.stateList = Object.values(STATES);
		this.currentStateIndex = 0;
	}

	getCurrentState() {
		return this.stateList[this.currentStateIndex];
	}

	next() {
		if (this.currentStateIndex < this.stateList.length - 1) {
			this.currentStateIndex++;
			return;
		}
		console.error("Out of state");
	}
}

export default {
	name: "addDataFromGroupwareSequence",
	components: {
		addDataFromGroupware,
		SelectType,
		addTerrainData,
	},
	data() {
		return {
			state: undefined,
			selectedFiles: [],
			type: "",
			axisShow: "",
			allowedFileTypes: "",
		};
	},
	created() {
		this.state = new State();
	},
	mounted() {
		this.stateMachine = new State();
	},
	unmounted() {
		this.stateMachine = new State();
	},
	computed: {
		showGetFromGroupwareDialog() {
			return this.state.getCurrentState() === STATES.SELECT_FROM_GROUPWARE;
		},
		showSelectTypeDialog() {
			return this.state.getCurrentState() === STATES.SELECT_TYPE;
		},
		showTerrainDataDialog() {
			return this.state.getCurrentState() === STATES.ADD_TERRAIN_DATA;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		closeGetFromGroupwareDialog() {
			this.$emit("close");
		},
		onAccept(detail) {
			this.selectedFiles = detail;
			if (1 < this.selectedFiles.length) {
				this.set_snackbar({
					text: this.$t("SELECT_A_SINGLE_FILE"),
					color: "rgba(153, 0,0,0.72)",
				});
				return;
			}
			this.state.next();
		},
		closeSelectTypeDialog() {
			this.showSelectTypeDialog = false;
			this.$emit("close");
		},
		openTerrainData(type, allowedFileTypes, axisShow) {
			this.type = type;
			this.allowedFileTypes = allowedFileTypes;
			this.axisShow = axisShow;

			this.state.next();
		},
		closeTerrainData() {
			this.$emit("close");
		},
		async uploadTerrainData(dialogData) {
			if (!dialogData) {
				this.closeTerrainData();
				return;
			}

			const name = dialogData[1];
			const bucket_id = this.selectedFiles[0].bucketId;
			const node_id = this.selectedFiles[0].id;
			const date = dialogData[2];
			const time = `${dialogData[3]}:00`;
			const axis = this.axisShow ? dialogData[4] : undefined;

			const endpointMap = {
				pointcloud: {
					url: `${import.meta.env.VITE_API_BASE}/assetLink/pointcloud/${this.$route.query.siteId}`,
					options: { bucket_id, node_id, name, date, time, axis },
					action: "get_point_cloud_list",
				},
				design: {
					url: `${import.meta.env.VITE_API_BASE}/assetLink/design/${this.$route.query.siteId}`,
					options: { bucket_id, node_id, name, date, time },
					action: "get_design_list",
				},
				lineworks: {
					url: `${import.meta.env.VITE_API_BASE}/assetLink/lineworks/${this.$route.query.siteId}`,
					options: { bucket_id, node_id, name },
				},
			};

			const endpoint = endpointMap[this.type];

			if (endpoint) {
				try {
					this.$store.commit("setLoading", true);
					await axios.post(endpoint.url, endpoint.options);
					if (endpoint.action) {
						this.$store.dispatch(endpoint.action, this.$route.query.siteId);
					}
				} catch (error) {
					console.error("Error uploading terrain data:", error);
				} finally {
					this.$store.commit("setLoading", false);
				}
			} else {
				console.warn(`Unsupported type: ${this.type}`);
			}

			this.$emit("close");
		},
	},
};
</script>